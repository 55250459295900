import React, { forwardRef, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, Grid, IconButton, TextField, makeStyles } from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ClearIcon from '@material-ui/icons/Clear';
import AddColumn from './AddColumn';

const Component = ({ collection, selectedTable, setSelectedTableAttribute }) => {
  const classes = useStyles();

  const onDragEnd = (result) => {
    if (!result.destination) { return; } // dropped outside the list
  
    const tempColumns = reorder(
      [...selectedTable.columns],
      result.source.index,
      result.destination.index
    );
    setSelectedTableAttribute('columns', tempColumns)
  }

  const removeColumn = (index) => {
    const tempColumns = [...selectedTable.columns];
    tempColumns.splice(index, 1);
    setSelectedTableAttribute('columns', tempColumns)
  }

  return (
    <Grid container spacing={3}>
      <Grid item item xs={12}>
        <Card>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  <AddColumn collection={collection} selectedTable={selectedTable} setSelectedTableAttribute={setSelectedTableAttribute} />
                  {selectedTable.columns && selectedTable.columns.length > 0 && selectedTable.columns.map((column, index) => (
                    <Draggable key={column.title} draggableId={column.title} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}>

                          {/* Draggable Element */}
                          <div style={{padding: `${grid}px ${grid*3}px ${grid}px ${grid}px`}}>
                            <h6 style={{margin: 0}}>{column.title}</h6>
                            {column.field}
                            <IconButton onClick={() => removeColumn(index)} className={classes.deleteButton}>
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                          
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Card>
      </Grid>
    </Grid>
  );
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 ${grid}px 0 0`,
  position: 'relative',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    padding: 0,
    position: 'absolute',
    top: 0,
    right: 0
  },
}));

export default Component