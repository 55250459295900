import React, { useEffect, useState, useRef } from 'react';
import { get, set } from 'lodash';
import { List, ListItem, ListItemText, ListItemSecondaryAction, ListItemAvatar, Avatar, Button, Popover, Select, MenuItem, 
  FormLabel, InputLabel, Switch, FormControl, 
  FormGroup, FormControlLabel, Checkbox, TextField, Grid, makeStyles } from '@material-ui/core';
import { Form } from 'react-bootstrap';
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

import { withAuthorization } from '../../session';
import { withAuthConsumer } from '../../session';
import { withFirebase } from '../../firebase';
import ArrangeColumns from './ArrangeColumns';
import AddRow from './AddRow';
import ArrangeRows from './ArrangeRows';
import PreviewTable from './PreviewTable';

const Configure = ({ orgId, firebase, selectedTable, setSelectedTable, collectionNames }) => {
  const classes = useStyles();

  const [collection, setCollection] = React.useState(null)
  const [filters, setFilters] = React.useState(null)

  const getCollection = (collectionid) => {
    if (!collectionid) { return }
    firebase.collection(orgId, collectionid).get().then(doc => {
      if (doc.exists) {
        const newCollection = {...doc.data()}
        setCollection(newCollection)
      } else {
        alert(`Couldn't find collection "${collectionid}"`)
      }
    }).catch(error => {
      alert(error)
    })
  }

  const getFilters = (collectionid) => {
    if (!collectionid) { return }
    firebase.filters(orgId).get().then(docs => {
      let tempFilters = {}
      if (!docs.empty) {
        docs.forEach(doc => {
          let data = doc.data()
          if (data.collection == collectionid) {
            tempFilters[doc.id] = data
          }
        })
      }
      setFilters(tempFilters)
    }).catch(error => {
      alert(error)
    })
  }

  const setSelectedTableAttribute = (path, value) => {
    const tempSelectedTable = { ...selectedTable }
    set(tempSelectedTable, path, value)
    setSelectedTable(tempSelectedTable)
  }

  useEffect(() => {
    getCollection(selectedTable.collection)
    getFilters(selectedTable.collection)
  }, [])

  return (
    <Grid container spacing={1}>

      {/* ---------- Select a Collection ---------- */}
      <Grid item xs={12}>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel>Associated Collection</InputLabel>
          <Select
            value={selectedTable.collection || ''}
            onChange={(e) => {
              setSelectedTableAttribute('collection', e.target.value) // set the table collection value
              getCollection(e.target.value) // fetch the collection
              getFilters(e.target.value) // fetch the filters associated with collection
            }}
            label="Associated Collection"
          >
            {collectionNames.map(name => {
              return <MenuItem value={name} key={name}>{name}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>

      {/* ---------- Arrange Columns ---------- */}
      {selectedTable.collection && <>
        <h6>Headers (Columns)</h6>
        <Grid item xs={12} style={{paddingBottom: 16}}>
          <ArrangeColumns collection={collection} selectedTable={selectedTable} setSelectedTableAttribute={setSelectedTableAttribute} />
        </Grid>
      </>}

      {/* ---------- Arrange Rows ---------- */}
      {selectedTable.collection && <>
        <h6>Data (Rows)</h6>
        <Grid item xs={12} style={{paddingBottom: 16}}>
          <ArrangeRows filters={filters} collection={collection} selectedTable={selectedTable} setSelectedTableAttribute={setSelectedTableAttribute} />
        </Grid>
      </>}

      {/* ---------- Preview Table ---------- */}
      {collection && selectedTable.columns && selectedTable.columns.length > 0 && selectedTable.rows && selectedTable.rows.length > 0 && <>
        <h6>Preview</h6>
        <Grid item xs={12}>
          <PreviewTable collection={collection} selectedTable={selectedTable} />
        </Grid>
      </>}

    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const condition = authUser => authUser

export default withAuthorization(condition)(withFirebase(withAuthConsumer(Configure)));