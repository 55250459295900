import React, { useState, useRef, useReducer } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Form, Button, ButtonGroup, InputGroup, Col } from "react-bootstrap"
import { Modal, Grid, IconButton, TextField, makeStyles } from '@material-ui/core'

import ConfigureFilter from './ConfigureFilter';

const Component = ({ collection, selectedTable, setSelectedTableAttribute }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [filter, setFilter] = React.useState({});
  const [addFilterOpen, setAddFilterOpen] = React.useState(false);

  const openModal = () => {
    setAddFilterOpen(true);
  }

  const closeModal = () => {
    setAddFilterOpen(false);
  }

  const addFilter = () => {
    let tempRows = selectedTable.rows ? [...selectedTable.rows] : []
    tempRows.push({
      type: 'filter',
      id: `${new Date().getTime()}`,
      filter: {...filter}
    })
    setSelectedTableAttribute('rows', tempRows)
    setFilter({})
    toast(`Filter ${filter.name} added!`, {
      position: toast.POSITION.BOTTOM_CENTER
    })
  }

  const addBlankRow = () => {
    let tempRows = selectedTable.rows ? [...selectedTable.rows] : []
    tempRows.push({type: 'blank', id: `${new Date().getTime()}`})
    setSelectedTableAttribute('rows', tempRows)
  }

  return (<>
      {/* Page */}
      <ButtonGroup style={{width: '100%', paddingBottom: 8}}>
        <Button variant="light" style={{border:'1px solid #000'}} size="sm" onClick={openModal}>
          Add Filter
        </Button>
        <Button variant="light" style={{border:'1px solid #000'}} size="sm" onClick={() => addBlankRow()}>
          Add Blank Row
        </Button>
      </ButtonGroup>

      {/* Modal */}
      <Modal
        open={addFilterOpen}
        onClose={closeModal}
      >
        <div style={modalStyle} className={classes.paper}>
          <ConfigureFilter collection={collection} filter={filter} setFilter={setFilter} addFilter={addFilter} closeModal={closeModal} />
          <ToastContainer />
        </div>
      </Modal>
  </>);
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'scroll',
    height: '80%',
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default (Component)
