import React, { useEffect, useState, useRef } from "react";
import { get, set } from 'lodash';
import Select from 'react-select'
import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.

import { Form, Button, InputGroup, ButtonGroup, ToggleButton, Col } from "react-bootstrap"
import { InputLabel, Input, FormHelperText, FormControl, FormLabel, RadioGroup, Radio, FormGroup, FormControlLabel, Switch, Checkbox, Grid, IconButton, TextField, makeStyles } from '@material-ui/core'

import { getAttributesFromProducts } from '../../helpers'

const AddRule = ({ collection, filter, setFilter }) => {
  const classes = useStyles();

  const [attributes, setAttributes] = React.useState(React.useMemo(() => {}, []))
  const [selectedAttribute, setSelectedAttribute] = React.useState(React.useMemo(() => {}, []))

  const [selectedAttributeOperator, setSelectedAttributeOperator] = React.useState(null);
  const [selectedAttributeNumericType, setSelectedAttributeNumericType] = React.useState(null);

  const [selectedAttributeValue, setSelectedAttributeValue] = React.useState(null); // for numeric
  const [selectedAttributeValues, setSelectedAttributeValues] = React.useState([]); // for string
  const [selectedAttributeBoolean, setSelectedAttributeBoolean] = React.useState(true); // for boolean

  // fetch collection associated with the filter
  const getAttributes = () => {
    setAttributes(getAttributesFromProducts(collection.products))
  }

  const changeSelectedAttribute = (attribute) => {
    setSelectedAttribute(attribute)
    // clear all fields when changing attribute
    setSelectedAttributeOperator(null)
    setSelectedAttributeNumericType(null)
    setSelectedAttributeValue(null)
    setSelectedAttributeValues([])
  }

  // Create list of all attribute options
  const attributesOptions = () => {
    return Object.keys(attributes).sort().map((key, index) => {
      return { value: key, label: key }
    })
  }

  // Create list of all numeric attribute options
  const attributesOptionsNumeric = () => {
    return Object.keys(attributes).sort().filter((key) => {
      return typeof Array.from(get(attributes, key))[0] == 'number'
    }).map((key, index) => {
      return { value: key, label: key }
    })
  }

  // Create list of STRING type attribute values to select from
  const attributeValues = () => {
    return Array.from(get(attributes, selectedAttribute.value)).sort().map((key, index) => {
      if (key == "") {
        return { value: key, label: "Null/Empty" }
      } else {
        return { value: key, label: key }
      }
    })
  }

  const validateRule = () => {
    if (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'string') { // String Rule
      if (selectedAttributeOperator && selectedAttributeValues && selectedAttributeValues.length > 0) { return true }
    } else if (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'number') { // Numeric Rule
      if (selectedAttributeOperator && selectedAttributeNumericType == 'Value' && typeof selectedAttributeValue == 'number') { return true }
      if (selectedAttributeOperator && selectedAttributeNumericType == 'Attribute' && typeof selectedAttributeValue == 'object') { return true }
    } else if (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'boolean') { // Boolean Rule
      return true
    } else {
      return false
    }
  }

  const addRule = () => {
    let tempFilter = { ...filter }
    if (!tempFilter.rules) {
      tempFilter.rules = []
    }
    let newRule = {
      attribute: selectedAttribute.value
    }
    if (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'string') { // String Rule
      newRule.type = selectedAttributeOperator
      if (selectedAttributeOperator !== 'contains') {
        newRule.value = selectedAttributeValues.map(value => { return value.value })
      } else {
        newRule.value = selectedAttributeValues
      }
    } else if (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'number') { // Numeric Rule
      newRule.type = selectedAttributeOperator
      if (selectedAttributeNumericType == 'Value') {
        newRule.value = selectedAttributeValue
      } 
      if (selectedAttributeNumericType == 'Attribute') {
        newRule.value = selectedAttributeValue.value
      }
    } else if (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'boolean') { // Boolean Rule
      newRule.type = 'is'
      newRule.value = selectedAttributeBoolean
    }
    tempFilter.rules.push(newRule)
    setFilter(tempFilter)
  }

  useEffect(() => {
    getAttributes()
  }, [])

  return (
    <Grid container spacing={1}>
      
      {/* ---------- Add Rule ---------- */}
      <Grid item xs={12} >

        {attributes && <>
          <h6><b>Select an attribute to create new rule</b></h6>
          <Select value={selectedAttribute} onChange={changeSelectedAttribute} options={attributesOptions()}/>

          {selectedAttribute && get(attributes, selectedAttribute.value).size > 0 && <>

            {/* Boolean Rule */}
            { (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'boolean') && <>

              {`${selectedAttribute.label} must be ${selectedAttributeBoolean ? 'True' : 'False'}`}
              <Switch checked={selectedAttributeBoolean} 
                onChange={(event) => {setSelectedAttributeBoolean(event.target.checked)}}
              />

            </>}

            {/* String Rule */}
            { (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'string') && <>

              <br/>
              <ButtonGroup toggle>
                {['includes', 'excludes', 'contains'].map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    type="radio"
                    variant="outline-dark"
                    value={radio}
                    checked={selectedAttributeOperator === radio}
                    onChange={(e) => {
                      setSelectedAttributeOperator(e.currentTarget.value)
                      setSelectedAttributeValues([])
                    }}
                  >
                    {radio}
                  </ToggleButton>
                ))}
              </ButtonGroup>

              {selectedAttributeOperator && selectedAttributeOperator !== 'contains' && <>
                <br/><br/><Select isMulti={true} value={selectedAttributeValues} onChange={setSelectedAttributeValues} options={attributeValues()} placeholder="Select values..."/>
              </>}
              
              {selectedAttributeOperator && selectedAttributeOperator == 'contains' && <>
                <br/><br/><TagsInput value={selectedAttributeValues} onChange={setSelectedAttributeValues} inputProps={{className: 'react-tagsinput-input', placeholder: "Enter text"}} onlyUnique={true}/>
              </>}

            </>}

            {/* Numeric Rule */}
            { (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'number') && <>

              <br />
              <ButtonGroup toggle>
                {['=', '<', '<=', '>', '>='].map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    type="radio"
                    variant="outline-dark"
                    value={radio}
                    checked={selectedAttributeOperator === radio}
                    onChange={(e) => setSelectedAttributeOperator(e.currentTarget.value)}
                  >
                    {radio}
                  </ToggleButton>
                ))}
              </ButtonGroup>
              {selectedAttributeOperator && <>
                <br/><br/><ButtonGroup toggle>
                  {['Value', 'Attribute'].map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      type="radio"
                      variant="outline-dark"
                      value={radio}
                      checked={selectedAttributeNumericType === radio}
                      onChange={(e) => {
                        setSelectedAttributeValue(0)
                        setSelectedAttributeNumericType(e.currentTarget.value)
                      }}
                    >
                      {radio}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </>}

              {selectedAttributeOperator && selectedAttributeNumericType == 'Value' && <>
                <br/><br/><Form.Control value={selectedAttributeValue} onChange={(event) => {setSelectedAttributeValue(event.target.value)}} />
              </>}
              
              {selectedAttributeOperator && selectedAttributeNumericType == 'Attribute' && <>
                <br/><br/><Select value={selectedAttributeValue} onChange={setSelectedAttributeValue} options={attributesOptionsNumeric()} placeholder="Select an attribute to compare..." />
              </>}

            </>}

            {/* Object/Unsupported */}
            { (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'object') && <>
              <h1>Unsupported</h1>
            </>}

            <br/>
            {validateRule() == true && <Button
              variant="outline-dark"
              onClick={addRule}
            >
              Add Rule
            </Button>}

          </>}

        </>}

      </Grid>

    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default AddRule;