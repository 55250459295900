import React, { useEffect, useState, useRef } from "react";
import { get, set } from 'lodash';
import { SPECIES_FILTERS, SPECIES_UNITS_FILTERS, NATIVE_FILTERS, CUSTOM_FILTERS } from "../../constants";
import Select from 'react-select'
import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.

import { Form, Button, InputGroup, ButtonGroup, ToggleButton, Col } from "react-bootstrap"
import { InputLabel, Input, FormHelperText, FormControl, FormLabel, RadioGroup, Radio, FormGroup, FormControlLabel, Switch, Checkbox, Grid, IconButton, TextField, makeStyles } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';

import { getAttributesFromProducts } from '../../helpers'
import { withAuthConsumer } from '../../session';
import { withFirebase } from '../../firebase';

const AddRule = ({ filterCollection, orgId, authUser, firebase, selectedFilter, setSelectedFilter }) => {
  const classes = useStyles();

  const [attributes, setAttributes] = React.useState(React.useMemo(() => {}, []))
  const [selectedAttribute, setSelectedAttribute] = React.useState(React.useMemo(() => {}, []))

  const [selectedAttributeOperator, setSelectedAttributeOperator] = React.useState(null);
  const [selectedAttributeNumericType, setSelectedAttributeNumericType] = React.useState(null);

  const [selectedAttributeValue, setSelectedAttributeValue] = React.useState(0); // for numeric
  const [selectedAttributeValues, setSelectedAttributeValues] = React.useState([]); // for string
  const [selectedAttributeBoolean, setSelectedAttributeBoolean] = React.useState(true); // for boolean

  // fetch collection associated with the filter
  const getCollection = () => {
    firebase.collection(orgId, filterCollection).get().then(doc => {
      if (doc.exists) {
        const newCollection = {...doc.data()}
        setAttributes(getAttributesFromProducts(newCollection.products))
      } else {
        alert(`Couldn't find collection "${filterCollection}"`)
      }
    }).catch(error => {
      alert(error)
    })
  }

  const changeSelectedAttribute = (attribute) => {
    setSelectedAttribute(attribute)
    setSelectedAttributeValues([]) // always clear attribute values when changing attribute
  }

  // Create list of all attribute options
  const attributesOptions = () => {
    return Object.keys(attributes).sort().map((key, index) => {
      return { value: key, label: key }
    })
  }

  // Create list of all numeric attribute options
  const attributesOptionsNumeric = () => {
    return Object.keys(attributes).sort().filter((key) => {
      return typeof Array.from(get(attributes, key))[0] == 'number'
    }).map((key, index) => {
      return { value: key, label: key }
    })
  }

  // Create list of STRING type attribute values to select from
  const attributeValues = () => {
    return Array.from(get(attributes, selectedAttribute.value)).sort().map((key, index) => {
      if (key == "") {
        return { value: key, label: "Null/Empty" }
      } else {
        return { value: key, label: key }
      }
    })
  }

  const addRule = () => {
    let tempSelectedFilter = { ...selectedFilter }
    if (!tempSelectedFilter.rules) {
      tempSelectedFilter.rules = []
    }
    let newRule = {
      attribute: selectedAttribute.value
    }
    if (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'string') { // String Rule
      newRule.type = selectedAttributeOperator
      if (selectedAttributeOperator !== 'contains') {
        newRule.value = selectedAttributeValues.map(value => { return value.value })
      } else {
        newRule.value = selectedAttributeValues
      }
    } else if (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'number') { // Numeric Rule
      newRule.type = selectedAttributeOperator
      if (selectedAttributeNumericType !== 'Value') {
        newRule.value = selectedAttributeValue.value
      } else {
        newRule.value = selectedAttributeValue
      }
    } else if (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'boolean') { // Boolean Rule
      newRule.type = 'is'
      newRule.value = selectedAttributeBoolean
    }
    tempSelectedFilter.rules.push(newRule)
    setSelectedFilter(tempSelectedFilter)
    console.log(tempSelectedFilter)
  }

  useEffect(() => {
    getCollection()
  }, [])

  return (
    <Grid container spacing={1}>
      
      {/* ---------- Add Rule ---------- */}
      <Grid item xs={12} >

        {attributes && <>
          <h6>Select an attribute to create new rule</h6>
          <Select value={selectedAttribute} onChange={changeSelectedAttribute} options={attributesOptions()} menuPortalTarget={document.querySelector('body')}/>
          <br />

          {selectedAttribute && get(attributes, selectedAttribute.value).size > 0 && <>

            {/* Boolean Rule */}
            { (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'boolean') && <>

              <FormControlLabel
                control={<Switch checked={selectedAttributeBoolean} 
                  onChange={(event) => {setSelectedAttributeBoolean(event.target.checked)}}
                />}
                label={`${selectedAttribute.label} must be ${selectedAttributeBoolean ? 'True' : 'False'}`}
                labelPlacement="start"
              />
            
            </>}

            {/* String Rule */}
            { (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'string') && <>

              <ButtonGroup toggle>
                {['includes', 'excludes', 'contains'].map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    type="radio"
                    variant="outline-dark"
                    value={radio}
                    checked={selectedAttributeOperator === radio}
                    onChange={(e) => {
                      setSelectedAttributeOperator(e.currentTarget.value)
                      setSelectedAttributeValues([])
                    }}
                  >
                    {radio}
                  </ToggleButton>
                ))}
              </ButtonGroup><br/><br/>

              {selectedAttributeOperator && selectedAttributeOperator !== 'contains' &&
                <Select isMulti={true} value={selectedAttributeValues} onChange={setSelectedAttributeValues} options={attributeValues()} menuPortalTarget={document.querySelector('body')}/>
              }
              
              {selectedAttributeOperator && selectedAttributeOperator == 'contains' &&
                <TagsInput value={selectedAttributeValues} onChange={setSelectedAttributeValues} inputProps={{className: 'react-tagsinput-input', placeholder: "Enter text"}} onlyUnique={true}/>
              }

            </>}

            {/* Numeric Rule */}
            { (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'number') && <>

              <ButtonGroup toggle>
                {['=', '<', '<=', '>', '>='].map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    type="radio"
                    variant="outline-dark"
                    value={radio}
                    checked={selectedAttributeOperator === radio}
                    onChange={(e) => setSelectedAttributeOperator(e.currentTarget.value)}
                  >
                    {radio}
                  </ToggleButton>
                ))}
              </ButtonGroup>
              <br/><br/>
              {selectedAttributeOperator && <>
                <ButtonGroup toggle>
                  {['Value', 'Attribute'].map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      type="radio"
                      variant="outline-dark"
                      value={radio}
                      checked={selectedAttributeNumericType === radio}
                      onChange={(e) => {
                        setSelectedAttributeValue(0)
                        setSelectedAttributeNumericType(e.currentTarget.value)
                      }}
                    >
                      {radio}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
                <br/><br/>
              </>}

              {selectedAttributeOperator && selectedAttributeNumericType == 'Value' &&
                <Form.Control value={selectedAttributeValue} onChange={(event) => {setSelectedAttributeValue(event.target.value)}} />
              }
              
              {selectedAttributeOperator && selectedAttributeNumericType == 'Attribute' &&
                <Select value={selectedAttributeValue} onChange={setSelectedAttributeValue} options={attributesOptionsNumeric()} menuPortalTarget={document.querySelector('body')}/>
              }

            </>}

            {/* Object/Unsupported */}
            { (typeof Array.from(get(attributes, selectedAttribute.value))[0] == 'object') && <>
              <h1>Unsupported</h1>
            </>}

            <br/>
            <Button
              variant="outline-dark"
              onClick={addRule}
            >
              Add Rule
            </Button>

          </>}

        </>}

      </Grid>

    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const condition = authUser => authUser

export default withFirebase(withAuthConsumer(AddRule));