import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';

import { CssBaseline, } from '@material-ui/core'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Firebase, { FirebaseContext } from './firebase';

const theme = createMuiTheme({
  palette: {
    // type: 'dark',
  },
  mixins: {
    toolbar: {
      minHeight: '56px'
    }
  }
});

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </FirebaseContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
