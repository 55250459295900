import React, { forwardRef, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Card, Grid, makeStyles } from '@material-ui/core';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

const Table = ({ products, setProducts }) => {
  const classes = useStyles();

  const [columns, setColumns] = React.useState([
    { title: 'New', field: 'isNew', editable: 'never', type: 'boolean', width: 1 },
    { title: 'Hidden', field: 'isHidden', type: 'boolean', width: 1 },
    { title: 'ID', field: 'productId', editable: 'never' },
    { title: 'Name', field: 'productName' },
    { title: 'Producer', field: 'producerName' },
    { title: 'Category', field: 'category' },
    { title: 'Units', field: 'units' },
    { title: 'Price', field: 'price', type: 'numeric', editable: 'never' },
    { title: 'Inventory', field: 'inventory', type: 'numeric', editable: 'never' },
    { title: 'Species', field: 'species', type: 'numeric', editable: 'never' },
    { title: 'THC', field: 'thcLevel', type: 'numeric', editable: 'never' },
    { title: 'CBD', field: 'cbdLevel', type: 'numeric', editable: 'never' },
  ]);

  const hideSelectedProducts = (e, data, boolean) => {
    data.forEach(d => {
      d.isHidden = boolean
      d.tableData.checked = false
    })
    setProducts([...products])
  }

  return (
    <Grid container spacing={3}>
      <Grid item item xs={12}>
        <Card>
          <MaterialTable
            icons={tableIcons}
            title="Items"
            columns={columns}
            data={products}
            actions={[
              {
                tooltip: 'Show All Selected Products',
                icon: () => <VisibilityIcon />,
                onClick: (e, data) => hideSelectedProducts(e, data, false)
              },
              {
                tooltip: 'Hide All Selected Products',
                icon: () => <VisibilityOffIcon />,
                onClick: (e, data) => hideSelectedProducts(e, data, true)
              },
            ]}
            options={{
              selection: true,
              actionsColumnIndex: 0,
              pageSize: 10,
              pageSizeOptions: [5, 10, 20, 50, 100],
            }}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  setProducts([...products, newData]);
                  resolve();
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const dataUpdate = [...products];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  dataUpdate[index].isNew = false;
                  setProducts([...dataUpdate]);
                  resolve();
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  const dataDelete = [...products];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setProducts([...dataDelete]);
                  resolve()
                }),
            }}
          />
        </Card>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  graphSidebar: {
    height: '100%',
  },
  sidebarSeparator: {
    borderColor: 'rgba(0,0,0,.3)',
  },
}));

export default Table