import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import clsx from 'clsx';

import { Container, makeStyles } from '@material-ui/core';

import ROUTES from '../../routes'

import Drawer from './Drawer' 
import Appbar from '../Appbar' 
import { withAuthConsumer } from '../../session';

const Dashboard = ({ authUser, match }) => {
  const classes = useStyles();
  const orgId = match.params.orgId;
  const [drawerOpen, setDrawerOpen] = React.useState(true);


  return (
    <div className={classes.root}>
      <Appbar drawerOpen={drawerOpen} hasDrawer={true} />
      <Drawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} orgId={orgId} />
      <main className={clsx(classes.content, !drawerOpen && classes.drawerCollapsed)}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <Switch>
            {authUser && ROUTES.filter(route => {
              return route.auth
            }).map((route, index) => {
              return (<Route
                key={index}
                path={route.path}
                exact={route.exact}
                name={route.name}
                component={route.component}
              />)
            })}
          </Switch>
        </Container>
      </main>
    </div>
  );
}

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100vh',
    marginLeft: `${drawerWidth}px`
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center', /* center items horizontally */
    // alignItems: 'center', /* center items vertically */
    minHeight: `calc(100% - ${theme.mixins.toolbar.minHeight})`
  },
  drawerCollapsed: {
    marginLeft: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
}));

export default withAuthConsumer(Dashboard);
