import React, { useEffect, useState, useRef } from 'react';
import { get, set } from 'lodash';
import { List, ListItem, ListItemText, ListItemSecondaryAction, ListItemAvatar, Avatar, Button, Popover, Select, MenuItem, 
  FormLabel, InputLabel, Switch, FormControl, 
  FormGroup, FormControlLabel, Checkbox, TextField, Grid, makeStyles } from '@material-ui/core';
import { Form } from 'react-bootstrap';
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

import { withAuthorization } from '../../session';
import { withAuthConsumer } from '../../session';
import { withFirebase } from '../../firebase';
import AddRule from './AddRule';
import SortRule from './SortRule';

import { getRgba } from '../../helpers'


const ConfigureFilter = ({ orgId, selectedFilter, setSelectedFilter, collectionNames }) => {
  const classes = useStyles();

  const setSelectedFilterAttribute = (path, value) => {
    const tempSelectedFilter = { ...selectedFilter }
    if (path === 'collection') { // always reset rules if we change the collection
      set(tempSelectedFilter, 'rules', [])
    }
    set(tempSelectedFilter, path, value)
    setSelectedFilter(tempSelectedFilter)
  }

  const removeRule = (index) => {
    let tempRules = selectedFilter.rules.slice()
    tempRules.splice(index, 1)
    setSelectedFilterAttribute('rules', tempRules)
  }

  const ruleHelperText = (rule) => {
    if (rule.type === 'is') {
      return `Rule evaluates true if attribute is ${rule.value}`
    }
    if (['=', '>', '<', '>=', '<='].includes(rule.type)) {
      return `Rule evaluates true if attribute ${rule.type} ${rule.value}`
    }
    if (rule.type === 'includes') {
      return `Rule evaluates true if attribute is any of the values`
    }
    if (rule.type === 'excludes') {
      return `Rule evaluates true if attribute is not any of the values`
    }
    if (rule.type === 'contains') {
      return `Rule evaluates true if attribute contains any of the text`
    }
  }

  const rulePrimaryText = (rule) => {
    if (rule.type === 'is' || ['=', '>', '<', '>=', '<='].includes(rule.type)) {
      return `"${rule.attribute}" ${rule.type} ${typeof rule.value == 'string' ? `"${rule.value}"` : rule.value}`
    }
    if (rule.type === 'contains') {
      return `"${rule.attribute}" ${rule.type} [${rule.value}]`
    }
    if (rule.type === 'includes') {
      return `"${rule.attribute}" is any of [${rule.value}]`
    }
    if (rule.type === 'excludes') {
      return `"${rule.attribute}" is not any of [${rule.value}]`
    }
    return 'Bad Rule'
  }

  return (
    <Grid container spacing={1}>

      {/* ---------- Collection ---------- */}
      <Grid item xs={12}>
        <FormControl variant="outlined" margin="dense" className={classes.formControl}>
          <InputLabel>Associated Collection</InputLabel>
          <Select
            value={selectedFilter.collection || ''}
            onChange={(e) => setSelectedFilterAttribute('collection', e.target.value)}
            label="Associated Collection"
          >
            {collectionNames.map(name => {
              return <MenuItem value={name} key={name}>{name}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Grid>

      {/* ---------- Current Rules ---------- */}
      {get(selectedFilter, 'rules.length') > 0 && <>
        <Grid item xs={12}>
          <h5>Rules ({get(selectedFilter, 'rules.length')})</h5>
          All rules must evaluate true for product to be included
          <List dense={true}>
            {selectedFilter.rules.map((rule, idx) => {
              return <ListItem key={idx}>
                <ListItemText
                  primary={rulePrimaryText(rule)}
                  secondary={ruleHelperText(rule)}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => removeRule(idx)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            })}
          </List>
        </Grid>
      </>}
      
      {/* ---------- Add Rule ---------- */}
      {get(selectedFilter, 'collection') && 
        <Grid item xs={12}>
          <AddRule filterCollection={get(selectedFilter, 'collection')} orgId={orgId} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter}></AddRule>
        </Grid>
      }

      {/* ---------- Sort Filter ---------- */}
      {get(selectedFilter, 'collection') &&
        <Grid item xs={12}>
          <hr/>
          <h5>Sort</h5>
          <SortRule filterCollection={get(selectedFilter, 'collection')} orgId={orgId} selectedFilter={selectedFilter} setSelectedFilterAttribute={setSelectedFilterAttribute}></SortRule>
          <hr/>
        </Grid>
      }

      {/* ---------- Label ---------- */}
      {get(selectedFilter, 'collection') &&
        <Grid item xs={12}>
          <h5>Label</h5>
          <Form.Control
            placeholder="Enter label"
            value={selectedFilter.label || ''}
            onChange={(e) => setSelectedFilterAttribute('label', e.target.value)}
          />
        </Grid>
      }

    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const condition = authUser => authUser

export default withAuthorization(condition)(withFirebase(withAuthConsumer(ConfigureFilter)));