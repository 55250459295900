import React, { forwardRef, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { get, set } from 'lodash';
import { Chip, Card, CardHeader, CardContent, CardActions, CardActionArea, Grid, IconButton, TextField, makeStyles } from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ClearIcon from '@material-ui/icons/Clear';
import AddRow from './AddRow';

const Component = ({ filters, collection, selectedTable, setSelectedTableAttribute }) => {
  const classes = useStyles();

  const onDragEnd = (result) => {
    if (!result.destination) { return; } // dropped outside the list
  
    const tempRows = reorder(
      [...selectedTable.rows],
      result.source.index,
      result.destination.index
    );
    setSelectedTableAttribute('rows', tempRows)
  }

  const removeRow = (index) => {
    const tempRows = [...selectedTable.rows];
    tempRows.splice(index, 1);
    setSelectedTableAttribute('rows', tempRows)
  }

  return (
    <Grid container spacing={3}>
      <Grid item item xs={12}>
        <Card>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  <AddRow filters={filters} collection={collection} selectedTable={selectedTable} setSelectedTableAttribute={setSelectedTableAttribute} />
                  {selectedTable.rows && selectedTable.rows.length > 0 && selectedTable.rows.map((row, index) => (
                    <Draggable key={row.id} draggableId={row.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}>

                          {/* Draggable Element */}
                          {row.type === 'filter' && <div style={{padding: `${grid}px ${grid*3}px ${grid}px ${grid}px`}}>
                            <h6 style={{margin: 0}}>{row.filter.name}</h6>
                            Label: {row.filter.label ? row.filter.label : '<none>'}, Sort: {get(row, 'filter.sort.attribute') ? get(row, 'filter.sort.attribute') + ',' : '<none>'} {get(row, 'filter.sort.direction')}
                            <div className={classes.chipHolder}>
                              {row.filter.rules && row.filter.rules.map((r, i) => {
                                return <Chip key={i} label={`${r.attribute} ${r.type} ${r.value}`} />
                              })}
                            </div>
                            <IconButton onClick={() => removeRow(index)} className={classes.deleteButton}>
                              <HighlightOffIcon />
                            </IconButton>
                          </div>}
                          {row.type !== 'filter' && <div style={{textAlign: 'center', padding: grid*2}}>
                            Blank Row
                            <IconButton onClick={() => removeRow(index)} className={classes.deleteButton}>
                              <HighlightOffIcon />
                            </IconButton>
                          </div>}
                        
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Card>
      </Grid>
    </Grid>
  );
}

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  position: 'relative',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: '100%',
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    padding: 0,
    position: 'absolute',
    top: 0,
    right: 0
  },
  chipHolder: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default Component