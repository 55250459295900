import React, { forwardRef, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Card, Grid, makeStyles } from '@material-ui/core';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { filterProducts } from '../../helpers'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const Component = ({ collection, selectedTable }) => {
  const classes = useStyles();

  const tableData = () => {
    let tempTableData = []
    selectedTable.rows.forEach(row => {
      if (row.type == 'blank') {
        tempTableData.push({})
      }
      if (row.type == 'filter') {
        // get list of filtered products
        let filteredProducts = filterProducts(collection.products, row.filter)
        // add filtered products to table data
        filteredProducts.forEach(p => {
          tempTableData.push(p)
        })
      }
    })
    return tempTableData
  }

  return (
    <Grid container spacing={3}>
      <Grid item item xs={12}>
        <Card>
          <MaterialTable
            icons={tableIcons}
            columns={selectedTable.columns.map((column, i) => { 
              return {
                render: rowData => <React.Fragment>
                  {Object.keys(rowData).length > 2 ? eval('`' + column.field.replace(/\${/g, '${rowData.') + '`') : 'Blank Row'}
                </React.Fragment>,
                sorting: false,
                ...column
              }
            })} // dont allow sorting by column
            data={tableData()}
            options={{
              headerStyle: {
                border: '1px solid black',
              },
              pageSize: 5,
              search: false,
              toolbar: false,
              draggable: false,
              paging: false,
            }}
          />
        </Card>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  graphSidebar: {
    height: '100%',
  },
  sidebarSeparator: {
    borderColor: 'rgba(0,0,0,.3)',
  },
}));

export default Component