import React, { useEffect, useState, useRef } from "react";
import { get, set } from 'lodash';
import { SPECIES_FILTERS, SPECIES_UNITS_FILTERS, NATIVE_FILTERS, CUSTOM_FILTERS } from "../../constants";
import Select from 'react-select'
import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.

import { ButtonGroup, ToggleButton, Col } from "react-bootstrap"
import { Grid, IconButton, TextField, makeStyles } from '@material-ui/core'

import { getAttributesFromProducts } from '../../helpers'

const SortRule = ({ collection, filter, setFilterAttribute }) => {
  const classes = useStyles();

  const [attributes, setAttributes] = React.useState(React.useMemo(() => {}, []))

  // fetch collection associated with the filter
  const getAttributes = () => {
    setAttributes(getAttributesFromProducts(collection.products))
  }

  // Create list of all numeric attribute options
  const attributesOptionsNumeric = () => {
    return Object.keys(attributes).sort().filter((key) => {
      return typeof Array.from(get(attributes, key))[0] == 'number'
    }).map((key, index) => {
      return { value: key, label: key }
    })
  }

  useEffect(() => {
    getAttributes()
  }, [])

  return (
    <Grid container spacing={1}>
      
      {/* ---------- Select Sort ---------- */}
      <Grid item xs={12} >

        {attributes && <>
          <h6>Select attribute to sort by</h6>
          <Select value={{value: get(filter, 'sort.attribute'), label: get(filter, 'sort.attribute')}} onChange={(e) => setFilterAttribute('sort.attribute', e.value)} options={attributesOptionsNumeric()}/>
          <br />

          {get(filter, 'sort.attribute') && <>
            <ButtonGroup toggle>
              {['Increasing', 'Decreasing'].map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  type="radio"
                  variant="outline-dark"
                  value={radio}
                  checked={get(filter, 'sort.direction') === radio.toLowerCase()}
                  onChange={(e) => setFilterAttribute('sort.direction', e.currentTarget.value.toLowerCase())}
                >
                  {radio}
                </ToggleButton>
              ))}
            </ButtonGroup>
            <br/><br/>
          </>}
        </>}

      </Grid>

    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default SortRule;