import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';

import { Form, Col } from 'react-bootstrap'
import { CircularProgress, Button, Container } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { withAuthConsumer } from '../../session';
import { withFirebase } from '../../firebase';
import CollectionTable from './CollectionTable'

const ManageCollections = ({ authUser, firebase, match }) => {
  const orgId = match.params.orgId;

  const [products, setProducts] = React.useState(React.useMemo(() => [], []))

  const [saveLoading, setSaveLoading] = React.useState(false)
  const [syncLoading, setSyncLoading] = React.useState(false)
  const [fetchLoading, setFetchLoading] = React.useState(false)

  const [collections, setCollections] = React.useState(React.useMemo(() => {}, []))
  const [collectionNames, setCollectionNames] = useState([])
  const [selectedCollection, setSelectedCollection] = React.useState();

  // get all of our collections
  const getCollections = useCallback(() => {
    // grab collection names from org
    firebase.organization(orgId).get().then(doc => {
      if (doc.exists) {
        const tempCollectionNames = doc.data().collectionNames
        setCollectionNames(tempCollectionNames)
        const requests = tempCollectionNames.map(name => {
          return firebase.collection(orgId, name).get()
        })
    
        // query collections and save to state
        Promise.all(requests).then((responses) => {
          const tempCollections = {}
          responses.forEach((response, index) => {
            tempCollections[tempCollectionNames[index]] = response.data()
            tempCollections[tempCollectionNames[index]].products = tempCollections[tempCollectionNames[index]].products.map((product) => {
              if (product.productName === undefined) {
                product.productName = ''
              }
              if (product.producerName === undefined) {
                product.producerName = ''
              }
              if (product.units === undefined) {
                product.units = ''
              }
              return product
            })
          })
          setCollections(tempCollections);
          setSelectedCollection(tempCollectionNames[0]);
          setProducts(tempCollections[tempCollectionNames[0]].products);
        });

      } else {
        alert('could not fetch org')
      }
    }).catch(error => {
      alert(error)
    })
  }, [firebase, orgId])

  useEffect(() => {
    getCollections()
  }, [])

  const saveCollection = () => {
    setSaveLoading(true);
    firebase.collection(orgId, selectedCollection).set({
      products,
      lastUpdated: new Date()
    }, {merge: true})
    .then(() => {
      setSaveLoading(false);
      getCollections();
      console.log('write success')
    })
    .catch((error) => {
      setSaveLoading(false);
      alert(error)
      console.log('write error', error)
    })
  }

  const syncCollection = () => {
    setSyncLoading(true);
    let tempSelectedCollectionMap = {}
    collections[selectedCollection].products.forEach(product => {
      tempSelectedCollectionMap[product.productId] = product
    })
    let tempCollections = { ...collections }
    Object.keys(tempCollections).forEach(key => {
      if (key == selectedCollection) {
        return
      }
      tempCollections[key].products = tempCollections[key].products.map(product => {
        let tempSelectedProduct = tempSelectedCollectionMap[product.productId]
        if (tempSelectedProduct) {
          product.productName = tempSelectedProduct.productName
          product.producerName = tempSelectedProduct.producerName
          product.displayName = tempSelectedProduct.displayName
          product.units = tempSelectedProduct.units
        }
        return product
      })
    })
    setCollections(tempCollections);
    setSyncLoading(false);
    console.log('synced')
  }

  const fetchApiData = () => {
    setFetchLoading(true);
    axios.get('https://us-central1-numo-cannabis.cloudfunctions.net/fetchCollectionData?collection='+selectedCollection)
    .then(function (response) {
      setFetchLoading(false);
      getCollections();
    })
    .catch(function (error) {
      setFetchLoading(false);
      alert(error)
    })
  }

  const selectCollection = (event) => {
    const newProducts = [...collections[event.target.value].products]
    setProducts(newProducts);
    setSelectedCollection(event.target.value);
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Form>
        <Form.Label>Select a Collection</Form.Label>
        <Form.Group>
          <Form.Control as="select" onChange={selectCollection} value={selectedCollection}>
            {collectionNames.map(name => {
              return <option value={name} key={name}>{name}</option>
            })}
          </Form.Control>
        </Form.Group>
      </Form>
      <CollectionTable products={products} setProducts={setProducts} authUser={authUser} />
      <br/>
      <Button
        onClick={saveCollection}
        variant="contained"
        color="primary"
        size="large"
        disabled={saveLoading}
        startIcon={<SaveIcon />}
        style={{marginRight: '10px'}}
      >
        {saveLoading && <CircularProgress size={14} />}
        {!saveLoading && 'Save Changes'}
      </Button>
      {authUser.role == 'admin' && collections && selectedCollection && <Button
        onClick={syncCollection}
        variant="contained"
        color="primary"
        size="large"
        disabled={syncLoading}
        startIcon={<SyncIcon />}
        style={{marginRight: '10px'}}
      >
        {syncLoading && <CircularProgress size={14} />}
        {!syncLoading && 'Sync This Collection To Other Collections'}
      </Button>}
      <Button
        onClick={fetchApiData}
        variant="contained"
        color="secondary"
        size="large"
        disabled={fetchLoading}
        startIcon={<CloudDownloadIcon />}
      >
        {fetchLoading && <CircularProgress size={14} />}
        {!fetchLoading && 'Fetch Data from TechPOS (Inventory, Price, THC/CBD)'}
      </Button>
      <br/>
      <br/>
      {/* {collections && selectedCollection &&
        `Last fetch from POS: ${collections[selectedCollection].lastFetchFromPos?.toDate()}`
      }<br /> */}
      {/* {collections && selectedCollection &&
        `Last updated: ${collections[selectedCollection].lastUpdated ? collections[selectedCollection].lastUpdated?.toDate() : 'Never'}`
      } */}
    </Container>
  )
}

export default withFirebase(withAuthConsumer(ManageCollections));