import React from 'react';
import { Link } from "react-router-dom";

import { Container } from '@material-ui/core';

import { withAuthConsumer } from '../../session';
// import LogoutButton from '../../components/Logout';

const Overview = ({ match, authUser }) => {
  const orgId = match.params.orgId;

  return (
    <Container maxWidth={false}>
      <h1>Organization: {orgId}</h1>
    </Container>
  )
}

export default withAuthConsumer(Overview);