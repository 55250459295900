import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import clsx from 'clsx';

import { Container, makeStyles } from '@material-ui/core';

import ROUTES from '../../routes'

import Appbar from '../Appbar' 
import { withAuthConsumer } from '../../session';

const Standard = ({ authUser }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Appbar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container} disableGutters>
          <Switch>
            {authUser && ROUTES.filter(route => {
                return route.auth
              }).map((route, index) => {
                return (<Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  component={route.component}
                />)
              })}
            {ROUTES.filter(route => {
              return !route.auth
            }).map((route, index) => {
              return (<Route
                key={index}
                path={route.path}
                exact={route.exact}
                name={route.name}
                component={route.component}
              />)
            })}
          </Switch>
        </Container>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100vh'
  },
  container: {
    display: 'flex',
    justifyContent: 'center', /* center items horizontally */
    // alignItems: 'center', /* center items vertically */
    minHeight: `calc(100% - ${theme.mixins.toolbar.minHeight})`,
  },
  appBarSpacer: theme.mixins.toolbar,
}));

export default withAuthConsumer(Standard)
