import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { makeStyles } from '@material-ui/core'

import './App.css';
import ROUTES from './routes'
import { withAuthProvider, withAuthConsumer } from './session';

// containers
import Dashboard from './containers/Dashboard';
import Standard from './containers/Standard';
import ViewMenu from './pages/ViewMenu';

const App = ({ authUser }) => {
  const classes = useStyles();

  return (
    <Router>
      <Switch> 
        <Route
          path='/o/:orgId/menus/:menuId'
          exact={true}
          name='View Menu'
          component={ViewMenu}
        />
        {authUser && ROUTES.filter(route => {
          return route.auth && route.container === 'Dashboard'
        }).map((route, index) => {
          return (<Route
            key={index}
            path={route.path}
            exact={route.exact}
            name={route.name}
            auth={route.auth}
            component={Dashboard}
          />)
        })}
        {authUser && ROUTES.filter(route => {
            return route.auth && route.container === 'Standard'
        }).map((route, index) => {
          return (<Route
            key={index}
            path={route.path}
            exact={route.exact}
            name={route.name}
            auth={route.auth}
            component={Standard}
          />)
        })}
        {ROUTES.filter(route => {
            return !route.auth && route.container === 'Standard'
        }).map((route, index) => {
          return (<Route
            key={index}
            path={route.path}
            exact={route.exact}
            name={route.name}
            auth={route.auth}
            component={Standard}
          />)
        })}
      </Switch>
    </Router>
  );
};

const useStyles = makeStyles((theme) => ({
  // appRoot: {
  //   display: 'flex'
  // }
}));

export default withAuthProvider(withAuthConsumer(App));
