import React, { useEffect, useState, useRef, useCallback } from 'react';
import { InputGroup, Button, Form, Spinner } from 'react-bootstrap';
import { Box, Card, CardContent, CardActions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Container, makeStyles } from '@material-ui/core';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { withAuthorization } from '../../session';
import { withAuthConsumer } from '../../session';
import { withFirebase } from '../../firebase';
import Configure from './Configure';

const ManageTable = ({ authUser, firebase, match }) => {
  const classes = useStyles();
  const orgId = match.params.orgId;

  const [tables, setTables] = React.useState(React.useMemo(() => {}, []))
  const [selectedTable, setSelectedTable] = React.useState()

  const [collectionNames, setCollectionNames] = React.useState([])

  // loading states
  const [saveTableLoading, setSaveTableLoading] = React.useState(false)
  const [cloneTableLoading, setCloneTableLoading] = React.useState(false)
  const [deleteTableLoading, setDeleteTableLoading] = React.useState(false)
  const [saveNewTableLoading, setSaveNewTableLoading] = React.useState(false)
  // input refs
  const newTableNameInput = useRef('')

  // Query data for our tables
  const getTables = useCallback(() => {
    const requests = []

    requests.push(firebase.tables(orgId).get())
    requests.push(firebase.organization(orgId).get())

    // query collections and save to state
    Promise.all(requests).then((responses) => {
      const tempTables = {}
      responses[0].docs.forEach(doc => {
        let tempTable = doc.data()
        tempTables[tempTable.name] = tempTable
      });
      setCollectionNames(responses[1].data().collectionNames)
      setTables(tempTables)
      console.log(tempTables)

      const tempSelectedTable = { ...tempTables[Object.keys(tempTables)[0]] }
      !selectedTable && setSelectedTable(tempSelectedTable)
    });
  }, [firebase, orgId, selectedTable])

  const saveTable = () => {
    setSaveTableLoading(true)
    const table = { ...selectedTable }

    firebase.table(orgId, table.name).update({
      ...table
    }).then(() => {
      getTables()
    }).catch((error) => {
      alert(error)
    }).then(() => {
      setSaveTableLoading(false)
    })
  }

  const cloneTable = async () => {
    setCloneTableLoading(true)
    const table = { ...selectedTable }

    const { value: cloneTableName } = await Swal.fire({
      title: 'Clone this table',
      text: 'Enter new table name',
      input: 'text',
      animation: false,
      showCancelButton: true,
      confirmButtonText: 'Create',
      cancelButtonText: 'Cancel',
      inputValidator: (value) => {
        if (!value) {
          return 'Please enter name for cloned table'
        }
      }
    })
    if (cloneTableName) {
      firebase.table(orgId, cloneTableName).get().then(doc => {
        if (doc.exists) {
          alert(`Error: Table with name "${cloneTableName}" already exists`)
          setCloneTableLoading(false)
        } else {
          firebase.table(orgId, cloneTableName).set({
            ...table,
            name: cloneTableName,
            // firestore does not allow directly nested arrays, must be array -> map -> array
            items: table.items?.map((item) => { return { group: item } }) || []
          }).then(() => {
            getTables()
          }).catch((error) => {
            alert(error)
          }).then(() => {
            setCloneTableLoading(false)
          })
        }
      })
    }
    setCloneTableLoading(false)
  }

  const deleteTable = () => {
    Swal.fire({
      title: 'Delete a Table',
      text: 'Are you sure?',
      icon: 'warning',
      animation: false,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      focusConfirm: false,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        setDeleteTableLoading(true)
        const table = { ...selectedTable }
        firebase.table(orgId, table.name).delete().then(() => {
          getTables()
        }).catch((error) => {
          alert(error)
        }).then(() => {
          setDeleteTableLoading(false)
        })
      }
    })
  }

  const saveNewTable = () => {
    setSaveNewTableLoading(true)
    const newTable = { name: newTableNameInput.current.value }
    firebase.table(orgId, newTable.name).get().then(doc => {
      if (doc.exists) {
        alert(`Error: Table with name "${newTable.name}" already exists`)
        setSaveNewTableLoading(false)
      } else {
        firebase.table(orgId, newTable.name).set(newTable).then(() => {
          getTables()
        }).catch((error) => {
          alert(error)
        }).then(() => {
          setSaveNewTableLoading(false)
        })
      }
    })
    setSaveNewTableLoading(false)
  }

  useEffect(() => {
    getTables()
  }, [])

  const selectTable = (tableName) => {
    const tempSelectedTable = {...tables[tableName]}
    setSelectedTable(tempSelectedTable)
  };

  return (
    <Container maxWidth={false} disableGutters>

      {/* Table Creator */}
      <label>Create new table</label>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Table name"
          ref={newTableNameInput}
        />
        <InputGroup.Append>
          <Button variant="outline-secondary" onClick={saveNewTable} disabled={saveNewTableLoading}>
            {saveNewTableLoading ? <Spinner animation="border" as="span" size="sm" /> : 'Create'}
          </Button>
        </InputGroup.Append>
      </InputGroup>

      {tables && Object.keys(tables).length > 0 && selectedTable && <>
      
      <Card className={classes.root}>
        <CardContent>
          <Form>
            <Form.Label>Select a Table</Form.Label>
            <Form.Control as="select" onChange={(e) => selectTable(e.target.value)} value={selectedTable.name}>
              {tables && Object.keys(tables).sort().map(name => {
                return <option key={name} value={name}>{name}</option>
              })}
            </Form.Control>
          </Form>
        </CardContent>

        <hr />

        <CardContent>
          <Configure collectionNames={collectionNames} orgId={orgId} selectedTable={selectedTable} setSelectedTable={setSelectedTable} />
        </CardContent>
      </Card>

      <Box className={classes.footerSpacer} />
        
      <Box className={classes.footer}>
        {/* action button row */}
        <Button variant="success" onClick={saveTable} disabled={saveTableLoading}>
          {saveTableLoading ? <Spinner animation="border" as="span" size="sm" /> : 'Save'}
        </Button>{' '}
        <Button variant="primary" onClick={cloneTable} disabled={cloneTableLoading}>
            {cloneTableLoading ? <Spinner animation="border" as="span" size="sm" /> : 'Clone'}
          </Button>{' '}
        <Button variant="danger" onClick={() => deleteTable()} disabled={deleteTableLoading}>
          {deleteTableLoading ? <Spinner animation="border" as="span" size="sm" /> : 'Delete'}
        </Button>{' '}
      </Box>
      
    </>}

    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  footerSpacer: {
    height: "62px",
    width: "100%"
  },
  footer: {
    zIndex: 1,
    position: 'fixed',
    bottom: 0,
    padding: "12px 24px",
    marginLeft: "-24px",
    width: "100%",
    backgroundColor: "#f5f5f5",
    border: "1px solid rgba(0, 0, 0, 0.12)"
  }
}));

const condition = authUser => authUser

export default withAuthorization(condition)(withFirebase(withAuthConsumer(ManageTable)));