import React, { useEffect, useState, useRef } from "react";
import { get, set } from 'lodash';
import { SPECIES_FILTERS, SPECIES_UNITS_FILTERS, NATIVE_FILTERS, CUSTOM_FILTERS } from "../../constants";
import Select from 'react-select'
import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.

import { Form, Button, InputGroup, ButtonGroup, ToggleButton, Col } from "react-bootstrap"
import { InputLabel, Input, FormHelperText, FormControl, FormLabel, RadioGroup, Radio, FormGroup, FormControlLabel, Switch, Checkbox, Grid, IconButton, TextField, makeStyles } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';

import { withAuthConsumer } from '../../session';
import { withFirebase } from '../../firebase';

const SortRule = ({ filterCollection, orgId, authUser, firebase, selectedFilter, setSelectedFilterAttribute }) => {
  const classes = useStyles();

  const [attributes, setAttributes] = React.useState(React.useMemo(() => {}, []))

  const processCollectionAttributes = (collection) => {
    const newCollectionAttributes = {}
    collection.products.forEach((product, index) => {
      Object.keys(product).forEach((key, index) => {
        if (key in newCollectionAttributes == false) {
          newCollectionAttributes[key] = new Set()
        }
        if (product[key]) {
          newCollectionAttributes[key].add(product[key])
        }
      })
    })
    setAttributes(newCollectionAttributes)
  }

  // fetch collection associated with the filter
  const getCollection = () => {
    firebase.collection(orgId, filterCollection).get().then(doc => {
      if (doc.exists) {
        const newCollection = {...doc.data()}
        processCollectionAttributes(newCollection)
      } else {
        alert(`Couldn't find collection "${filterCollection}"`)
      }
    }).catch(error => {
      alert(error)
    })
  }

  // Create list of all numeric attribute options
  const attributesOptionsNumeric = () => {
    return Object.keys(attributes).sort().filter((key) => {
      return typeof Array.from(get(attributes, key))[0] == 'number'
    }).map((key, index) => {
      return { value: key, label: key }
    })
  }

  useEffect(() => {
    getCollection()
  }, [])

  return (
    <Grid container spacing={1}>
      
      {/* ---------- Select Sort ---------- */}
      <Grid item xs={12} >

        {attributes && <>
          <h6>Select attribute to sort by</h6>
          <Select value={{value: get(selectedFilter, 'sort.attribute'), label: get(selectedFilter, 'sort.attribute')}} onChange={(e) => setSelectedFilterAttribute('sort.attribute', e.value)} options={attributesOptionsNumeric()} menuPortalTarget={document.querySelector('body')}/>
          <br />

          {get(selectedFilter, 'sort.attribute') && <>
            <ButtonGroup toggle>
              {['Increasing', 'Decreasing'].map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  type="radio"
                  variant="outline-dark"
                  value={radio}
                  checked={get(selectedFilter, 'sort.direction') === radio.toLowerCase()}
                  onChange={(e) => setSelectedFilterAttribute('sort.direction', e.currentTarget.value.toLowerCase())}
                >
                  {radio}
                </ToggleButton>
              ))}
            </ButtonGroup>
            <br/><br/>
          </>}
        </>}

      </Grid>

    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const condition = authUser => authUser

export default withFirebase(withAuthConsumer(SortRule));