import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import clsx from 'clsx';

import { Drawer, Divider, List, ListItem, ListItemIcon, ListSubheader, ListItemText, Button, makeStyles, useTheme } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CategoryIcon from '@material-ui/icons/Category';
import ListIcon from '@material-ui/icons/List';
import ViewListIcon from '@material-ui/icons/ViewList';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import TableChartIcon from '@material-ui/icons/TableChart';
import FilterListIcon from '@material-ui/icons/FilterList';

import logo from '../../logo.svg'
import { withAuthConsumer } from '../../session';
import { withFirebase } from '../../firebase';

const DashboardDrawer = ({ firebase, authUser, drawerOpen, setDrawerOpen, orgId }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      classes={{ paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose) }}
    >
      {/* header */}
      <div>
        <div className={classes.appBarSpacer} />
        {/* links */}
        <List className={classes.drawerList}>
          <ListItem button component={NavLink} exact to={`/o/${orgId}`} activeStyle={{ background: theme.palette.grey[300], color: theme.palette.text.primary }}>
              <ListItemIcon>
                <DashboardIcon htmlColor="#000"/>
              </ListItemIcon>
              <ListItemText primary="Dashboard" classes={{ primary: classes.listItemText }}/>
          </ListItem>
          <ListSubheader>
            Manage
          </ListSubheader>
          <ListItem button component={NavLink} exact to={`/o/${orgId}/collections`} activeStyle={{ background: theme.palette.grey[300], color: theme.palette.text.primary }}>
              <ListItemIcon>
                <CategoryIcon htmlColor="#000"/>
              </ListItemIcon>
              <ListItemText primary="Collections" classes={{ primary: classes.listItemText }}/>
          </ListItem>
          <ListItem button component={NavLink} exact to={`/o/${orgId}/menus`} activeStyle={{ background: theme.palette.grey[300], color: theme.palette.text.primary }}>
              <ListItemIcon>
                <TableChartIcon htmlColor="#000"/>
              </ListItemIcon>
              <ListItemText primary="Menus" classes={{ primary: classes.listItemText }}/>
          </ListItem>
          <ListItem button component={NavLink} exact to={`/o/${orgId}/tables`} activeStyle={{ background: theme.palette.grey[300], color: theme.palette.text.primary }}>
              <ListItemIcon>
                <ViewListIcon htmlColor="#000"/>
              </ListItemIcon>
              <ListItemText primary="Tables" classes={{ primary: classes.listItemText }}/>
          </ListItem>
        </List>
      </div>

      {/* footer */}
      <div>
        {/* <List className={classes.drawerList}>
          {!authUser && <ListItem button component={NavLink} to="/login">
              <ListItemIcon>
                <ExitToAppIcon color="primary"/>
              </ListItemIcon>
              <ListItemText primary="Login" classes={{ primary: classes.listItemText }}/>
          </ListItem>}
          {authUser && <ListItem button component={Link} to="/" onClick={firebase.doSignOut}>
              <ListItemIcon>
                <ExitToAppIcon color="primary"/>
              </ListItemIcon>
              <ListItemText primary="Logout" classes={{ primary: classes.listItemText }}/>
          </ListItem>}
        </List> */}
        <div className={classes.drawerFooter}>
          <Button disableRipple fullWidth className={classes.drawerFooterIcon} 
            onClick={() => drawerOpen ? setDrawerOpen(false) : setDrawerOpen(true)}>
            {drawerOpen && <ChevronLeftIcon />}
            {!drawerOpen && <ChevronRightIcon />}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    flex: '0 0 auto',
    padding: '8px 0',
    textAlign: 'center',
    // backgroundColor: 'rgba(0,0,255,.1)'
  },
  drawerHeaderLogo: {
    height: '40px',
  },
  drawerPaper: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    borderRight: '0px',
    justifyContent: 'space-between',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderRight: '0px',
    justifyContent: 'space-between',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  drawerList: {
    marginTop: '1px',
    padding: '0px'
  },
  drawerFooter: {
    // backgroundColor: 'rgba(0,0,0,.3)'
  },
  drawerFooterIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '8px 24px',
  },
  listItemText: {
    fontWeight: 'bold',
  },
  appBarSpacer: theme.mixins.toolbar,
}));

export default withFirebase(withAuthConsumer(DashboardDrawer))
