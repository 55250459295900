import React, { useEffect, useState, useRef, useCallback } from 'react';
import { InputGroup, Button, Form, Spinner } from 'react-bootstrap';
import { Box, Card, CardContent, CardActions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Container, makeStyles } from '@material-ui/core';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { withAuthorization } from '../../session';
import { withAuthConsumer } from '../../session';
import { withFirebase } from '../../firebase';
import ConfigureFilter from './ConfigureFilter';

const ManageFilter = ({ authUser, firebase, match }) => {
  const classes = useStyles();
  const orgId = match.params.orgId;

  const [filters, setFilters] = React.useState(React.useMemo(() => {}, []))
  const [selectedFilter, setSelectedFilter] = React.useState()

  const [collectionNames, setCollectionNames] = React.useState([])

  // loading states
  const [saveFilterLoading, setSaveFilterLoading] = React.useState(false)
  const [deleteFilterLoading, setDeleteFilterLoading] = React.useState(false)
  const [saveNewFilterLoading, setSaveNewFilterLoading] = React.useState(false)
  // input refs
  const newFilterNameInput = useRef('')

  // Query data for our filters
  const getFilters = useCallback(() => {
    const requests = []

    requests.push(firebase.filters(orgId).get())
    requests.push(firebase.organization(orgId).get())

    // query collections and save to state
    Promise.all(requests).then((responses) => {
      const tempFilters = {}
      responses[0].docs.forEach(doc => {
        let tempFilter = doc.data()
        tempFilters[tempFilter.name] = tempFilter
      });
      setCollectionNames(responses[1].data().collectionNames)
      setFilters(tempFilters)
      console.log(tempFilters)

      const tempSelectedFilter = { ...tempFilters[Object.keys(tempFilters)[0]] }
      !selectedFilter && setSelectedFilter(tempSelectedFilter)
    });
  }, [firebase, orgId, selectedFilter])

  const saveFilter = () => {
    setSaveFilterLoading(true)
    const filter = { ...selectedFilter }

    firebase.filter(orgId, filter.name).set({
      ...filter
    }).then(() => {
      getFilters()
    }).catch((error) => {
      alert(error)
    }).then(() => {
      setSaveFilterLoading(false)
    })
  }

  const deleteFilter = () => {
    Swal.fire({
      title: 'Delete a Filter',
      text: 'Are you sure?',
      icon: 'warning',
      animation: false,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      focusConfirm: false,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        setDeleteFilterLoading(true)
        const filter = { ...selectedFilter }
        firebase.filter(orgId, filter.name).delete().then(() => {
          getFilters()
        }).catch((error) => {
          alert(error)
        }).then(() => {
          setDeleteFilterLoading(false)
        })
      }
    })
  }

  const saveNewFilter = () => {
    setSaveNewFilterLoading(true)
    const newFilter = { name: newFilterNameInput.current.value }
    firebase.filter(orgId, newFilter.name).set(newFilter, { merge: true }).then(() => {
      getFilters()
    }).catch((error) => {
      alert(error)
    }).then(() => {
      setSaveNewFilterLoading(false)
    })
  }

  useEffect(() => {
    getFilters()
  }, [])

  const selectFilter = (filterName) => {
    const tempSelectedFilter = {...filters[filterName]}
    setSelectedFilter(tempSelectedFilter)
  };

  return (
    <Container maxWidth={false} disableGutters>

      {/* Filter Creator */}
      <label>Create new filter</label>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Filter name"
          ref={newFilterNameInput}
        />
        <InputGroup.Append>
          <Button variant="outline-secondary" onClick={saveNewFilter} disabled={saveNewFilterLoading}>
            {saveNewFilterLoading ? <Spinner animation="border" as="span" size="sm" /> : 'Create'}
          </Button>
        </InputGroup.Append>
      </InputGroup>

      {filters && Object.keys(filters).length > 0 && selectedFilter && <>
      
      <Card className={classes.root}>
        <CardContent>
          <Form>
            <Form.Label>Select a Filter</Form.Label>
            <Form.Control as="select" onChange={(e) => selectFilter(e.target.value)} value={selectedFilter.name}>
              {filters && Object.keys(filters).sort().map(name => {
                return <option key={name} value={name}>{name}</option>
              })}
            </Form.Control>
          </Form>
        </CardContent>

        <hr />

        <CardContent>
          <ConfigureFilter collectionNames={collectionNames} orgId={orgId} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
        </CardContent>
      </Card>

      <Box className={classes.footerSpacer} />
        
      <Box className={classes.footer}>
        {/* action button row */}
        <Button variant="success" onClick={saveFilter} disabled={saveFilterLoading}>
          {saveFilterLoading ? <Spinner animation="border" as="span" size="sm" /> : 'Save'}
        </Button>{' '}
        <Button variant="danger" onClick={() => deleteFilter()} disabled={deleteFilterLoading}>
          {deleteFilterLoading ? <Spinner animation="border" as="span" size="sm" /> : 'Delete'}
        </Button>{' '}
      </Box>
      
    </>}

    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  footerSpacer: {
    height: "62px",
    width: "100%"
  },
  footer: {
    zIndex: 1,
    position: 'fixed',
    bottom: 0,
    padding: "12px 24px",
    marginLeft: "-24px",
    width: "100%",
    backgroundColor: "#f5f5f5",
    border: "1px solid rgba(0, 0, 0, 0.12)"
  }
}));

const condition = authUser => authUser

export default withAuthorization(condition)(withFirebase(withAuthConsumer(ManageFilter)));