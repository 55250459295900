import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as QueryString from "query-string"
import { InputGroup, Button, Form, Spinner } from 'react-bootstrap';
import { Box, Card, CardContent, CardActions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Container, makeStyles } from '@material-ui/core';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { withAuthorization } from '../../session';
import { withAuthConsumer } from '../../session';
import { withFirebase } from '../../firebase';
import ConfigureMenu from './ConfigureMenu'

const MySwal = withReactContent(Swal)

const ManageMenus = ({ authUser, firebase, match, location }) => {
  const classes = useStyles();
  const orgId = match.params.orgId;

  const [menus, setMenus] = React.useState(React.useMemo(() => {}, []))
  const [tables, setTables] = React.useState(React.useMemo(() => {}, []))
  const [selectedMenu, setSelectedMenu] = React.useState()

  // loading states
  const [saveMenuLoading, setSaveMenuLoading] = React.useState(false)
  const [cloneMenuLoading, setCloneMenuLoading] = React.useState(false)
  const [deleteMenuLoading, setDeleteMenuLoading] = React.useState(false)
  const [saveNewMenuLoading, setSaveNewMenuLoading] = React.useState(false)

  // input refs
  const newMenuNameInput = useRef('')

  // Query data for our menus
  const getData = useCallback(() => {
    const requests = []

    requests.push(firebase.menus(orgId).get())
    requests.push(firebase.tables(orgId).get())

    // query collections and save to state
    Promise.all(requests).then((responses) => {
      const tempMenus = {}
      responses[0].docs.forEach(doc => {
        let tempMenu = doc.data()
        // firestore does not allow directly nested arrays, decode array -> map -> array format
        tempMenu.items = tempMenu.items?.map((item) => { return item.group })
        tempMenus[tempMenu.name] = tempMenu
      });
      const tempTables = {}
      responses[1].docs.forEach(doc => {
        let tempTable = doc.data()
        tempTables[tempTable.name] = tempTable
      })
      setMenus(tempMenus)
      setTables(tempTables)

      const tempSelectedMenu = { ...tempMenus[Object.keys(tempMenus)[0]] }
      !selectedMenu && setSelectedMenu(tempSelectedMenu)
    });
  }, [firebase, orgId, selectedMenu])

  const saveMenu = () => {
    setSaveMenuLoading(true)
    const menu = { ...selectedMenu }

    firebase.menu(orgId, menu.name).update({
      ...menu,
      // firestore does not allow directly nested arrays, must be array -> map -> array
      items: menu.items?.map((item) => { return { group: item } }) || []
    }).then(() => {
      getData()
    }).catch((error) => {
      alert(error)
    }).then(() => {
      setSaveMenuLoading(false)
    })
  }

  const cloneMenu = async () => {
    setCloneMenuLoading(true)
    const menu = { ...selectedMenu }

    const { value: cloneMenuName } = await Swal.fire({
      title: 'Clone this menu',
      text: 'Enter new menu name',
      input: 'text',
      animation: false,
      showCancelButton: true,
      confirmButtonText: 'Create',
      cancelButtonText: 'Cancel',
      inputValidator: (value) => {
        if (!value) {
          return 'Please enter name for cloned menu'
        }
      }
    })
    if (cloneMenuName) {
      firebase.menu(orgId, cloneMenuName).get().then(doc => {
        if (doc.exists) {
          alert(`Error: Menu with name "${cloneMenuName}" already exists`)
          setCloneMenuLoading(false)
        } else {
          firebase.menu(orgId, cloneMenuName).set({
            ...menu,
            name: cloneMenuName,
            // firestore does not allow directly nested arrays, must be array -> map -> array
            items: menu.items?.map((item) => { return { group: item } }) || []
          }).then(() => {
            getData()
          }).catch((error) => {
            alert(error)
          }).then(() => {
            setCloneMenuLoading(false)
          })
        }
      })
    }
    setCloneMenuLoading(false)
  }

  const deleteMenu = () => {
    Swal.fire({
      title: 'Delete a Menu',
      text: 'Are you sure?',
      icon: 'warning',
      animation: false,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      focusConfirm: false,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        setDeleteMenuLoading(true)
        const menu = { ...selectedMenu }
        firebase.menu(orgId, menu.name).delete().then(() => {
          getData()
        }).catch((error) => {
          alert(error)
        }).then(() => {
          setDeleteMenuLoading(false)
        })
      }
    })
  }

  const saveNewMenu = () => {
    setSaveNewMenuLoading(true)
    const newMenu = { name: newMenuNameInput.current.value }
    firebase.menu(orgId, newMenu.name).get().then(doc => {
      if (doc.exists) {
        alert(`Error: Menu with name "${newMenu.name}" already exists`)
        setSaveNewMenuLoading(false)
      } else {
        firebase.menu(orgId, newMenu.name).set(newMenu).then(() => {
          getData()
        }).catch((error) => {
          alert(error)
        }).then(() => {
          setSaveNewMenuLoading(false)
        })
      }
    })
    setSaveNewMenuLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  const selectMenu = (menuName) => {
    const tempSelectedMenu = {...menus[menuName]}
    setSelectedMenu(tempSelectedMenu)
  };

  return (
    <Container maxWidth={false} disableGutters>
      {/* Menu Creator */}
      <label>Create new menu</label>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Menu name"
          ref={newMenuNameInput}
        />
        <InputGroup.Append>
          <Button variant="outline-secondary" onClick={saveNewMenu} disabled={saveNewMenuLoading}>
            {saveNewMenuLoading ? <Spinner animation="border" as="span" size="sm" /> : 'Create'}
          </Button>
        </InputGroup.Append>
      </InputGroup>

      {menus && Object.keys(menus).length > 0 && selectedMenu && <>
      
        <Card className={classes.root}>
          <CardContent>
            <Form>
              <Form.Label>Select a Menu</Form.Label>
              <Form.Control as="select" onChange={(e) => selectMenu(e.target.value)} value={selectedMenu.name}>
                {menus && Object.keys(menus).sort().map(name => {
                  return <option key={name} value={name}>{name}</option>
                })}
              </Form.Control>
            </Form>
          </CardContent>

          <hr />

          <CardContent>
            <ConfigureMenu tables={tables} selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />
          </CardContent>
        </Card>
      
        <Box className={classes.footerSpacer} />
        
        <Box className={classes.footer}>
          {/* action button row */}
          <Button variant="success" onClick={saveMenu} disabled={saveMenuLoading}>
            {saveMenuLoading ? <Spinner animation="border" as="span" size="sm" /> : 'Save'}
          </Button>{' '}
          <Button variant="primary" onClick={cloneMenu} disabled={cloneMenuLoading}>
            {cloneMenuLoading ? <Spinner animation="border" as="span" size="sm" /> : 'Clone'}
          </Button>{' '}
          {/* <Button variant="secondary">Secondary</Button>{' '} */}
          <Button variant="warning" href={`/o/${orgId}/menus/${selectedMenu.name}`} target='_blank'>
            View
          </Button>{' '}
          {/* <Button variant="primary">Order</Button>{' '} */}
          <Button variant="danger" onClick={() => deleteMenu()} disabled={deleteMenuLoading}>
            {deleteMenuLoading ? <Spinner animation="border" as="span" size="sm" /> : 'Delete'}
          </Button>{' '}
        </Box>

      </>}

    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  footerSpacer: {
    height: "62px",
    width: "100%"
  },
  footer: {
    zIndex: 1,
    position: 'fixed',
    bottom: 0,
    padding: "12px 24px",
    marginLeft: "-24px",
    width: "100%",
    backgroundColor: "#f5f5f5",
    border: "1px solid rgba(0, 0, 0, 0.12)"
  }
}));

const condition = authUser => authUser

export default withAuthorization(condition)(withFirebase(withAuthConsumer(ManageMenus)));