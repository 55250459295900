import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { Box, Table, TableContainer, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { getRgba, filterProducts } from '../../helpers'

const Component = ({ table, menu }) => { // group = a column
  const classes = useStyles();

  const getRowsFromFilter = (collection, filter) => {
    return filterProducts(collection.products, filter.filter)
  }

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} size={menu.denseTable ? 'small' : 'medium'}>
        <TableHead style={{backgroundColor: getRgba(menu.tableStyle?.headerBackgroundColor?.rgb)}}>
          <TableRow>
            {/* Side Label */}
            {!menu.tableStyle?.hideLabels && <TableCell 
              className={clsx(classes.labelCell, menu.tableStyle?.hideHeaderLines && classes.headerCellNoBorder)} 
              style={{
                borderColor: getRgba(menu.tableStyle?.lineColor?.rgb),
              }} 
            />}

            {table.columns ? table.columns.map((cell, index) => {
              return <TableCell key={index} align="left" className={clsx(menu.tableStyle?.hideHeaderLines && classes.headerCellNoBorder)} 
                style={{
                  borderColor: getRgba(menu.tableStyle?.lineColor?.rgb),
                }}
              >
                <Box fontSize={`${menu.font?.size?.header}rem`} color={menu.tableStyle?.headerColor?.hex} fontWeight='bold'>
                  {cell.title}
                </Box>
              </TableCell>
            }) : null}
          </TableRow>
        </TableHead>

        <TableBody style={{backgroundColor: getRgba(menu.tableStyle?.bodyBackgroundColor?.rgb)}}>
          {table.rows.map((filterOrSpacer, sind) => {
            return <React.Fragment key={sind}>
              {filterOrSpacer.type === 'filter' && <>
                {!menu.tableStyle?.hideLabels && <TableRow>
                  <TableCell rowSpan={getRowsFromFilter(table.collection, filterOrSpacer).length + 1} className={clsx(classes.labelCell, menu.tableStyle?.hideBodyLines && classes.bodyCellNoBorder)}
                    style={{
                      maxWidth: `${menu.tableStyle?.labelSize}em`,
                      backgroundColor: getRgba(menu.tableStyle?.labelBackgroundColor?.rgb),
                      borderColor: getRgba(menu.tableStyle?.lineColor?.rgb)
                    }}
                  >
                    {getRowsFromFilter(table.collection, filterOrSpacer).length != 0 && <Box fontWeight='bold' className={`${classes.vertical}`}
                      fontSize={`${menu.font?.size?.label}rem`}
                      color={menu.tableStyle?.labelColor?.hex}
                    >
                      {filterOrSpacer.filter.label}
                    </Box>}
                  </TableCell>
                </TableRow>}
                {getRowsFromFilter(table.collection, filterOrSpacer).map((row, rind) => {
                  return <TableRow key={rind}>
                    {table.columns.map((cell, cellind) => {
                      return <TableCell key={cellind} align="left" className={clsx(menu.tableStyle?.hideBodyLines && classes.bodyCellNoBorder)} style={{borderColor: getRgba(menu.tableStyle?.lineColor?.rgb)}}>
                        <Box fontSize={`${menu.font?.size?.body}rem`} color={menu.tableStyle?.bodyColor?.hex}>
                          {eval('`' + cell.field.replace(/\${/g, '${row.') + '`')}
                        </Box>
                      </TableCell>
                    })}
                  </TableRow>
                })}
              </>}

              {filterOrSpacer.type ==='blank' && <TableRow>
                {!menu.tableStyle?.hideLabels && <TableCell className={clsx(classes.labelCell, menu.tableStyle?.hideDividerLines && classes.bodyCellNoBorder)}
                  style={{
                    borderColor: getRgba(menu.tableStyle?.lineColor?.rgb)
                  }}
                  >&nbsp;
                </TableCell>}
                {table.columns.map((cell, cellind) => {
                  return <TableCell key={cellind} className={clsx(menu.tableStyle?.hideDividerLines && classes.bodyCellNoBorder)} style={{borderColor: getRgba(menu.tableStyle?.lineColor?.rgb)}}>
                    &nbsp;
                  </TableCell> 
                })}
              </TableRow>
              }
            </React.Fragment>
          })}
        </TableBody>

      </Table>
    </TableContainer>
  )
}

const useStyles = makeStyles({
  table: {
    minWidth: 400,
  },
  container: {
    boxShadow: 'none !important',
  },
  cell: {
    borderBottom: '0px',
  },
  hr: {
    border: '0 !important'
  },
  labelCell: {
    padding: '0px !important',
  },
  headerCellNoBorder: {
    borderBottom: '0px',
  },
  vertical: {
    transform: 'rotate(-90deg)', 
    webkitTransform: 'rotate(-90deg)', // Safari
    MozTransform: 'rotate(-90deg)', // Firefox
    OTransform: 'rotate(-90deg)', // Opera
    msTransform: 'rotate(-90deg)', // IE
    textAlign: 'center'
  },
});

export default React.memo(Component)