import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyBbO2pj4AgXBhYriOB_1L4mC3RPOLrvDu8",
  authDomain: "numo-cannabis.firebaseapp.com",
  databaseURL: "https://numo-cannabis.firebaseio.com",
  projectId: "numo-cannabis",
  storageBucket: "numo-cannabis.appspot.com",
  messagingSenderId: "186331549674",
  appId: "1:186331549674:web:879b64c036d7953675ec93",
  measurementId: "G-ZE2PZTWZVV"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
  }

    // *** Auth API ***
    doCreateUserWithEmailAndPassword = (email, password) => {
      return this.auth.createUserWithEmailAndPassword(email, password);
    }

    doSignInWithEmailAndPassword = (email, password) => {
      return this.auth.signInWithEmailAndPassword(email, password);
    }

    doSignOut = () => { 
      return this.auth.signOut(); 
    }

    doPasswordReset = email => {
      return this.auth.sendPasswordResetEmail(email);
    }
 
    doPasswordUpdate = password => {
      return this.auth.currentUser.updatePassword(password);
    }

    doSendEmailVerification = () => {
      return this.auth.currentUser.sendEmailVerification();
    }

    onAuthUserListener = (next, fallback) =>
      this.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          this.user(authUser.uid)
            .get()
            .then(snapshot => {
              const dbUser = snapshot.data();
              // merge auth and db user
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                ...dbUser,
              };
              next(authUser);
            });
        } else {
          fallback();
        }
      });

    // *** User API ***
    user = (uid) => this.db.doc(`users/${uid}`);
    users = () => this.db.collection('users');

    // *** Stores API ***
    store = (storeid) => this.db.doc(`stores/${storeid}`);
    stores = () => this.db.collection('stores');

    // *** Organizations API ***
    organization = (orgid) => this.db.doc(`organizations/${orgid}`);

    // *** Collections API ***
    collection = (orgid, collectionid) => this.db.doc(`organizations/${orgid}/collections/${collectionid}`);
    collections = (orgid) => this.db.collection(`organizations/${orgid}/collections`);

    // *** Menus API ***
    menu = (orgid, menuid) => this.db.doc(`organizations/${orgid}/menus/${menuid}`);
    menus = (orgid) => this.db.collection(`organizations/${orgid}/menus`);

    // *** Tables API ***
    table = (orgid, tableid) => this.db.doc(`organizations/${orgid}/tables/${tableid}`);
    tables = (orgid) => this.db.collection(`organizations/${orgid}/tables`);

    // *** Filters API ***
    filter = (orgid, filterid) => this.db.doc(`organizations/${orgid}/filters/${filterid}`);
    filters = (orgid) => this.db.collection(`organizations/${orgid}/filters`);
}
 
export default Firebase;