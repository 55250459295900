import React, { useEffect, useState, useRef } from 'react';
import { get, set } from 'lodash';
import { Button, Popover, Select, MenuItem, FormLabel, InputLabel, Switch, FormControl, FormGroup, FormControlLabel, Checkbox, TextField, Grid, makeStyles } from '@material-ui/core';
import { SketchPicker } from 'react-color';

import { withAuthorization } from '../../session';
import { withAuthConsumer } from '../../session';
import { withFirebase } from '../../firebase';
import ArrangeMenu from './ArrangeMenu';

import { getRgba } from '../../helpers'

const ConfigureMenu = ({ selectedMenu, setSelectedMenu, tables }) => {
  const classes = useStyles();
  const [colorAnchor, setColorAnchor] = React.useState(null);
  const [colorAttribute, setColorAttribute] = React.useState(null);

  const handleChangeColor = (e, v) => {
    setColorAnchor(e.currentTarget)
    setColorAttribute(v)
  }

  const setSelectedMenuAttribute = (path, value) => {
    const tempSelectedMenu = { ...selectedMenu }
    set(tempSelectedMenu, path, value)
    setSelectedMenu(tempSelectedMenu)
  }

  const setMenuItems = (newMenuItems) => {
    setSelectedMenuAttribute('items', newMenuItems)
  }

  const getMenuItems = () => {
    if (!selectedMenu.items || !selectedMenu.items.length) {
      const emptyArray = [];
      emptyArray.push([])
      return emptyArray
    }
    return selectedMenu.items
  }

  return (
    <Grid container spacing={1}>

      {console.log(selectedMenu)}

      {/* ---------- React Beautiful DnD Menu Ordering ---------- */}
      <Grid item xs={12}>
        <ArrangeMenu menuItems={getMenuItems()} setMenuItems={setMenuItems} tables={tables} />
      </Grid>

      {/* ---------- Title/Background ---------- */}
      <Grid item xs={6}>
        <TextField
          label="Menu Title"
          placeholder="Enter a menu title"
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{ shrink: true, }}
          value={selectedMenu.title || ''}
          onChange={(e) => setSelectedMenuAttribute('title', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Background Image"
          placeholder="Enter a url"
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{ shrink: true, }}
          value={selectedMenu.style?.background?.image || ''}
          onChange={(e) => setSelectedMenuAttribute('style.background.image', e.target.value)}
        />
      </Grid>

      {/* ---------- Font Sizes ---------- */}
      <Grid item xs={3}>
        <TextField
          label="Font Size - Table Header (0.1-10 multiplier)"
          placeholder="Enter a multiplier between 0.1 and 20"
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{ shrink: true, }}
          type='number'
          value={selectedMenu.font?.size?.header || ''}
          onChange={(e) => setSelectedMenuAttribute('font.size.header', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Font Size - Table Body (0.1-10 multiplier)"
          placeholder="Enter a multiplier between 0.1 and 10"
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{ shrink: true, }}
          type='number'
          value={selectedMenu.font?.size?.body || ''}
          onChange={(e) => setSelectedMenuAttribute('font.size.body', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Font Size - Title (0.1-10 multiplier)"
          placeholder="Enter a multiplier between 0.1 and 10"
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{ shrink: true, }}
          type='number'
          value={selectedMenu.font?.size?.title || ''}
          onChange={(e) => setSelectedMenuAttribute('font.size.title', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Font Size - Label (0.1-10 multiplier)"
          placeholder="Enter a multiplier between 0.1 and 10"
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{ shrink: true, }}
          type='number'
          value={selectedMenu.font?.size?.label || ''}
          onChange={(e) => setSelectedMenuAttribute('font.size.label', e.target.value)}
        />
      </Grid>

      {/* ---------- Spacing ---------- */}
      <Grid item xs={3}>
        <TextField
          label="Spacing - Top (% of screen height)"
          placeholder="Enter a value between 0 and 100"
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{ shrink: true, }}
          type='number'
          value={selectedMenu.spacing?.top || ''}
          onChange={(e) => setSelectedMenuAttribute('spacing.top', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Spacing - Title (% of screen width)"
          placeholder="Enter a value between 0 and 100"
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{ shrink: true, }}
          type='number'
          value={selectedMenu.spacing?.title || ''}
          onChange={(e) => setSelectedMenuAttribute('spacing.title', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Spacing - Left/Right (% of screen width)"
          placeholder="Enter a value between 0 and 100"
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{ shrink: true, }}
          type='number'
          value={selectedMenu.spacing?.sides || ''}
          onChange={(e) => setSelectedMenuAttribute('spacing.sides', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Spacing - Tables (% of screen width)"
          placeholder="Enter a value between 0 and 100"
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{ shrink: true, }}
          type='number'
          value={selectedMenu.spacing?.tables || ''}
          onChange={(e) => setSelectedMenuAttribute('spacing.tables', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Label Size (0.1-10 multiplier)"
          placeholder="Enter a value between 0.1 and 10"
          fullWidth
          margin="dense"
          variant="outlined"
          InputLabelProps={{ shrink: true, }}
          type='number'
          value={selectedMenu.tableStyle?.labelSize || ''}
          onChange={(e) => setSelectedMenuAttribute('tableStyle.labelSize', e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <FormGroup row>
          <FormLabel component="legend">Display Options</FormLabel>
          <FormControlLabel
            control={<Switch checked={selectedMenu.centerContent || false} onChange={(e,v) => setSelectedMenuAttribute('centerContent', v)} />}
            label="Center Content Vertically"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Switch checked={selectedMenu.showTimestamp || false} onChange={(e,v) => setSelectedMenuAttribute('showTimestamp', v)} />}
            label="Show Timestamp"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Switch checked={selectedMenu.denseTable || false} onChange={(e,v) => setSelectedMenuAttribute('denseTable', v)} />}
            label="Dense Table"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Switch checked={selectedMenu.hideTitle || false} onChange={(e,v) => setSelectedMenuAttribute('hideTitle', v)} />}
            label="Hide Title"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Switch checked={selectedMenu.tableStyle?.hideLabels || false} onChange={(e,v) => setSelectedMenuAttribute('tableStyle.hideLabels', v)} />}
            label="Hide Labels"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Switch checked={selectedMenu.tableStyle?.hideHeaderLines || false} onChange={(e,v) => setSelectedMenuAttribute('tableStyle.hideHeaderLines', v)} />}
            label="Hide Table Header Line"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Switch checked={selectedMenu.tableStyle?.hideBodyLines || false} onChange={(e,v) => setSelectedMenuAttribute('tableStyle.hideBodyLines', v)} />}
            label="Hide Table Body Lines"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Switch checked={selectedMenu.tableStyle?.hideDividerLines || false} onChange={(e,v) => setSelectedMenuAttribute('tableStyle.hideDividerLines', v)} />}
            label="Hide Divider Lines"
            labelPlacement="bottom"
          />
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <FormGroup row>
          <FormLabel component="legend">Color Options</FormLabel>
          <FormControlLabel
            control={<Button
              variant="outlined" 
              disableElevation
              disableFocusRipple
              style={{backgroundColor: getRgba(selectedMenu.style?.title?.color?.rgb)}} 
              onClick={e => handleChangeColor(e, 'style.title.color')}
            >&nbsp;</Button>}
            label="Menu Title"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Button
              variant="outlined" 
              disableElevation
              disableFocusRipple
              style={{backgroundColor: getRgba(selectedMenu.style?.background?.color?.rgb)}} 
              onClick={e => handleChangeColor(e, 'style.background.color')}
            >&nbsp;</Button>}
            label="Menu Background"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Button
              variant="outlined" 
              disableElevation
              disableFocusRipple
              style={{backgroundColor: getRgba(selectedMenu.tableStyle?.bodyBackgroundColor?.rgb)}} 
              onClick={e => handleChangeColor(e, 'tableStyle.bodyBackgroundColor')}
            >&nbsp;</Button>}
            label="Table Body Background"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Button
              variant="outlined" 
              disableElevation
              disableFocusRipple
              style={{backgroundColor: getRgba(selectedMenu.tableStyle?.headerBackgroundColor?.rgb)}} 
              onClick={e => handleChangeColor(e, 'tableStyle.headerBackgroundColor')}
            >&nbsp;</Button>}
            label="Table Header Background"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Button
              variant="outlined" 
              disableElevation
              disableFocusRipple
              style={{backgroundColor: getRgba(selectedMenu.tableStyle?.headerColor?.rgb)}} 
              onClick={e => handleChangeColor(e, 'tableStyle.headerColor')}
            >&nbsp;</Button>}
            label="Table Header Text"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Button
              variant="outlined" 
              disableElevation
              disableFocusRipple
              style={{backgroundColor: getRgba(selectedMenu.tableStyle?.bodyColor?.rgb)}} 
              onClick={e => handleChangeColor(e, 'tableStyle.bodyColor')}
            >&nbsp;</Button>}
            label="Table Body Text"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Button
              variant="outlined" 
              disableElevation
              disableFocusRipple
              style={{backgroundColor: getRgba(selectedMenu.tableStyle?.labelColor?.rgb)}} 
              onClick={e => handleChangeColor(e, 'tableStyle.labelColor')}
            >&nbsp;</Button>}
            label="Table Label Text"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Button
              variant="outlined" 
              disableElevation
              disableFocusRipple
              style={{backgroundColor: getRgba(selectedMenu.tableStyle?.labelBackgroundColor?.rgb)}} 
              onClick={e => handleChangeColor(e, 'tableStyle.labelBackgroundColor')}
            >&nbsp;</Button>}
            label="Table Label Background"
            labelPlacement="bottom"
          />
          <FormControlLabel
            control={<Button
              variant="outlined" 
              disableElevation
              disableFocusRipple
              style={{backgroundColor: getRgba(selectedMenu.tableStyle?.lineColor?.rgb)}} 
              onClick={e => handleChangeColor(e, 'tableStyle.lineColor')}
            >&nbsp;</Button>}
            label="Table Line Color"
            labelPlacement="bottom"
          />
        </FormGroup>
      </Grid>
      
      <Popover
        open={Boolean(colorAnchor)}
        anchorEl={colorAnchor}
        onClose={() => setColorAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SketchPicker
          color={ get(selectedMenu, `${colorAttribute}.rgb`) }
          onChange={(c) => setSelectedMenuAttribute(colorAttribute, c)}
        />
      </Popover>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const condition = authUser => authUser

export default withAuthorization(condition)(withFirebase(withAuthConsumer(ConfigureMenu)));