import React, { useState, useRef, useReducer } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Select from 'react-select'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Form, Button, ButtonGroup, InputGroup, Col } from "react-bootstrap"
import { Grid, IconButton, TextField, makeStyles } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox';
import { getAttributesFromProducts } from '../../helpers'

const MySwal = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
}));

const Component = ({ collection, selectedTable, setSelectedTableAttribute }) => {
  const classes = useStyles();

  const variableSelectRef = useRef(null);
  const headerStringRef = useRef(null);
  const fieldStringRef = useRef(null);

  // Create list of all attribute options
  const attributesOptions = () => {
    return Object.keys(getAttributesFromProducts(collection.products)).sort().map((key, index) => {
      return { value: key, label: key }
    })
  }

  const insertVariable = (variable) => {
    let temp = fieldStringRef.current.value
    fieldStringRef.current.value = temp + "${" + variable.value + "}"
  }

  const addColumn = async () => {
    let { value: newColumn } = await MySwal.fire({
      title: 'Add Column',
      html: <>
        <div id="swal2-content" className="swal2-html-container" style={{display: 'block'}}>Header</div>
        <Form.Control ref={headerStringRef} className="swal2-input" placeholder="Text for column header" style={{display: 'flex'}} />
        <div id="swal2-content" className="swal2-html-container" style={{display: 'block'}}>Values (May contain variables)</div>
        <Form.Control ref={fieldStringRef} className="swal2-input" placeholder="Text for row data" style={{display: 'flex'}} />
        <Select ref={variableSelectRef} isClearable={true} placeholder="Insert variable to row data" onBlur={e => {
          variableSelectRef.current.state.value = null // clear field on blur
        }} onChange={insertVariable} options={attributesOptions()}/>
      </>,
      animation: false,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      preConfirm: () => {
        return {
          title: headerStringRef.current.value,
          field: fieldStringRef.current.value
        }
      }
    })
    if (newColumn == null) { return }
    let tempColumns = selectedTable.columns ? [...selectedTable.columns] : []
    tempColumns.push(newColumn)
    setSelectedTableAttribute('columns', tempColumns)
  }

  return (
    <IconButton aria-label="delete" onClick={() => addColumn()} >
      <AddBoxIcon />
    </IconButton>
  );
}

export default (Component)
