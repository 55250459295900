import React, { Component, useEffect, useState } from 'react';
import { Link as RouterLink, Redirect, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab'

import { withFirebase } from '../../../firebase';

const LoginForm = ({ firebase, history }) => {
  const classes = useStyles();

  const [buttonLoading, setButtonLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [emailField, setEmailField] = React.useState('');
  const [passwordField, setPasswordField] = React.useState('');

  const onSubmit = (event) => {
    event.preventDefault();
    setError(null);
    setButtonLoading(true)

    const email = emailField;
    const password = passwordField;
    firebase.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        history.push('/')
      })
      .catch(error => {
        setError(error);
      })
      .then(() => {
        setButtonLoading(false)
      })
  };

  const isValid = () => {
    if (!emailField || !passwordField) {
      return false
    }
    return true
  }

  return (
    <form className={classes.form} onSubmit={onSubmit} noValidate>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={emailField}
        onChange={(e) => {setEmailField(e.target.value)}}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={passwordField}
        onChange={(e) => {setPasswordField(e.target.value)}}
      />
      {/* <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Remember me"
      /> */}
      {error &&
        <Alert variant="outlined" severity="error">
          {error.message}
        </Alert>}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!isValid() || buttonLoading}
        className={classes.submit}
      >
        {buttonLoading ? 'Signing in...' : 'Sign In'}
      </Button>
      <Grid container>
        <Grid item xs>
          <Link component={RouterLink} to="/forgot">
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link component={RouterLink} to="/signup">
            Don't have an account? Sign Up
          </Link>
        </Grid>
      </Grid>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

export default withRouter(withFirebase(LoginForm))