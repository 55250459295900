import React, { useEffect, useState, useRef } from 'react';
import { get, set } from 'lodash';
import { List, ListItem, ListItemText, ListItemSecondaryAction, ListItemAvatar, Avatar, Popover, Select, MenuItem, 
  FormLabel, InputLabel, Switch, FormControl, 
  FormGroup, FormControlLabel, Checkbox, TextField, Grid, makeStyles } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

import { withAuthorization } from '../../session';
import { withAuthConsumer } from '../../session';
import { withFirebase } from '../../firebase';
import AddRule from './FilterAddRule';
import SortRule from './FilterSortRule';

const ConfigureFilter = ({ collection, filter, setFilter, addFilter, closeModal }) => {
  const classes = useStyles();

  const setFilterAttribute = (path, value) => {
    const tempFilter = { ...filter }
    if (path === 'collection') { // always reset rules if we change the collection
      set(tempFilter, 'rules', [])
    }
    set(tempFilter, path, value)
    setFilter(tempFilter)
  }

  const removeRule = (index) => {
    let tempRules = filter.rules.slice()
    tempRules.splice(index, 1)
    setFilterAttribute('rules', tempRules)
  }

  const ruleHelperText = (rule) => {
    if (rule.type === 'is') {
      return `Rule evaluates true if attribute is ${rule.value}`
    }
    if (['=', '>', '<', '>=', '<='].includes(rule.type)) {
      return `Rule evaluates true if attribute ${rule.type} ${rule.value}`
    }
    if (rule.type === 'includes') {
      return `Rule evaluates true if attribute is any of the values`
    }
    if (rule.type === 'excludes') {
      return `Rule evaluates true if attribute is not any of the values`
    }
    if (rule.type === 'contains') {
      return `Rule evaluates true if attribute contains any of the text`
    }
  }

  const rulePrimaryText = (rule) => {
    if (rule.type === 'is' || ['=', '>', '<', '>=', '<='].includes(rule.type)) {
      return `"${rule.attribute}" ${rule.type} ${typeof rule.value == 'string' ? `"${rule.value}"` : rule.value}`
    }
    if (rule.type === 'contains') {
      return `"${rule.attribute}" ${rule.type} [${rule.value}]`
    }
    if (rule.type === 'includes') {
      return `"${rule.attribute}" is any of [${rule.value}]`
    }
    if (rule.type === 'excludes') {
      return `"${rule.attribute}" is not any of [${rule.value}]`
    }
    return 'Bad Rule'
  }

  return (
    <Grid container spacing={1}>

      <h5 style={{margin: 'auto'}}><b>Add a filter</b></h5>

      {/* ---------- Label ---------- */}
      <Grid item xs={12}>
      <h6><b>Name</b></h6>
        <Form.Control
          placeholder="Enter name"
          value={filter.name || ''}
          onChange={(e) => setFilterAttribute('name', e.target.value)}
        />
        <hr/>
      </Grid>

      {/* ---------- Current Rules ---------- */}
      <Grid item xs={12}>
        <h6><b>Rules ({get(filter, 'rules.length') || 0})</b></h6>
        All rules must evaluate true for product to be included.
        {get(filter, 'rules.length') > 0 ? <List dense={true}>
          {filter.rules.map((rule, idx) => {
            return <ListItem key={idx}>
              <ListItemText
                primary={rulePrimaryText(rule)}
                secondary={ruleHelperText(rule)}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => removeRule(idx)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          })}
        </List> : <><br/><p style={{margin: 10}}>Add a rule to get started.</p></>}
      </Grid>

      {/* ---------- Add Rule ---------- */}
        <Grid item xs={12}>
        <AddRule collection={collection} filter={filter} setFilter={setFilter}></AddRule>
      </Grid>

      {/* ---------- Sort Filter ---------- */}
        <Grid item xs={12}>
          <hr/>
          <h6><b>Sorting</b></h6>
          <SortRule collection={collection} filter={filter} setFilterAttribute={setFilterAttribute}></SortRule>
          <hr/>
        </Grid>

      {/* ---------- Label ---------- */}
      <Grid item xs={12}>
      <h6><b>Label</b> (Displays on left side of filter group)</h6>
        <Form.Control
          placeholder="Enter label"
          value={filter.label || ''}
          onChange={(e) => setFilterAttribute('label', e.target.value)}
        />
      </Grid>

      {/* ---------- Submit ---------- */}

      <ButtonGroup style={{width: '100%', marginTop: '2rem'}}>
        <Button variant="outline-dark" size="lg" onClick={addFilter}>
          Add
        </Button>
        <Button variant="outline-dark" size="lg" onClick={closeModal}>
          Close
        </Button>
      </ButtonGroup>
      
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const condition = authUser => authUser

export default withAuthorization(condition)(withFirebase(withAuthConsumer(ConfigureFilter)));