export const getRgba = (rgb, a) => {
  if (rgb) {
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${a || rgb.a})`
  } else {
    return `rgba(0, 0, 0, 0)`
  }
}

export const getAttributesFromProducts = (products) => {
  const attributes = {}
  products.forEach((product, index) => {
    Object.keys(product).forEach((key, index) => {
      if (key in attributes === false) {
        attributes[key] = new Set()
      }
      if (product[key]) {
        attributes[key].add(product[key])
      }
    })
  })
  return attributes
}

export const filterProducts = (products, filter) => {
  let filteredProducts = products.filter(product => {
    // test rules
    if (filter.rules === undefined) { return true } // if no rules we're good
    for (let i = 0; i < filter.rules.length; i++) {
      let rule = filter.rules[i]
      // numeric rule
      if (['=', '<', '>', '<=', '>='].includes(rule.type)) {
        // if its a number use it, otherwise grab attribute value
        let value = typeof rule.value == 'number' ? rule.value : product[rule.value]
        // if we get undefined after grabbing attribute value it fails
        if (value === undefined) { return false }
        // get operator
        let operator = rule.type == '=' ? '==' : rule.type
        // try to evaluate expression
        let result;
        try {
          result = eval(`${product[rule.attribute]} ${operator} ${value}`)
        } catch (e) {
          alert('Math Error in Rule')
        }
        // if expression evaluates false it fails
        if (!result) { return false }
      } 
      // string rule
      if (['includes', 'excludes', 'contains'].includes(rule.type)) {
        // if none of the values are the product attribute it fails
        if (rule.type === 'includes' && !rule.value.includes(product[rule.attribute])) {
          return false
        }
        // if any of the values are the product attribute it fails
        if (rule.type === 'excludes' && rule.value.includes(product[rule.attribute])) {
          return false
        }
        // if any of the values are part of the attribute
        if (rule.type === 'contains') {
          var contains = false // starts off as false (does not contain)
          for (let i = 0; i < rule.value.length; i++) { // check all string values
            if (product[rule.attribute].includes(rule.value[i])) { // if attribute contains any string value we are good
              contains = true
            }
          }
          if (!contains) { // if didnt contain then return false
            return false
          }
        }
      }
      // boolean rule
      if (rule.type === 'is' && product[rule.attribute] && (product[rule.attribute] != rule.value)) { // if attribute and value dont match we fail
        return false
      }
    }
    // we are good, include this product
    return true
  })
  if (filter.sort !== undefined) {
    filteredProducts.sort((a, b) => {
      if (filter.sort.direction === 'increasing') {
        return a[filter.sort.attribute] - b[filter.sort.attribute]
      }
      return b[filter.sort.attribute] - a[filter.sort.attribute]
    })
  }
  return filteredProducts
}