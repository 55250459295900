import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid, Container, useMediaQuery } from '@material-ui/core';

import { withAuthConsumer } from '../../session';
import { withFirebase } from '../../firebase';

import TableView from './TableView'

const ViewMenu = ({ authUser, firebase, match }) => {
  const orgId = match.params.orgId;
  const menuName = match.params.menuId;
  const classes = useStyles();

  const matches = useMediaQuery('(min-width:600px)');

  const [menu, setMenu] = React.useState()

  // footer last fetch time stuff
  const [timeSinceLastFetch, setTimeSinceLastFetch] = React.useState() // store time since last fetch
  const [lastFetchTime, setLastFetchTime] = React.useState(moment()) // store time of last fetch
  const lastFetchTimeRef = useRef(lastFetchTime) // bind to ref because setInterval is a closure
  lastFetchTimeRef.current = lastFetchTime;

  const getMenuAndPopulateCollections = () => {
    // fetch the menu
    firebase.menu(orgId, menuName).get().then(doc => {
      if (doc.exists) {
        const newMenu = {...doc.data()}
        // for all "columns"
        newMenu.items.forEach((col, ind) => {
          // for all "rows"
          col.group.forEach(item => {
            // if item is a table fetch associated collection
            if (item.type === 'table') {
              // fetch collection associated with the menu
              firebase.collection(orgId, item.collection).get().then(doc => {
                if (doc.exists) {
                  item.collection = {...doc.data()}
                } else {
                  console.log(`Couldn't find collection "${newMenu.collection}"`)
                }
              }).catch(error => {
                alert(error)
              })
            }
          })
        })
        setMenu(newMenu)
        setLastFetchTime(moment())
        console.log('Fetching menu', newMenu)
      } else {
        console.log(`Couldn't find menu: "${menuName}"`)
      }
    })
  }

  useEffect(() => {
    getMenuAndPopulateCollections()
    setInterval(() => {
      getMenuAndPopulateCollections()
    }, 1000 * 60 * 10); // 1000ms * 60 = 60 seconds * 10 = 10 minutes
    setInterval(() => {
      setTimeSinceLastFetch(moment().diff(moment(lastFetchTimeRef.current)))
    }, 1000 * 1); // every second
  }, [])

  return (
    <div className={classes.root}>

      {menu?.showTimestamp && <Box className={classes.timestamp}>{`Last updated ${Math.round(timeSinceLastFetch/1000)} seconds ago`}</Box>}

      {menu && <main
        className={clsx(classes.content, menu?.centerContent && classes.center)} 
        style={{
          padding: `${menu.spacing?.top}vh ${menu.spacing?.sides}vw`, 
          backgroundImage: `url(${menu?.style?.background?.image})`,
          backgroundColor: menu?.style?.background?.color?.hex,
        }}
      >
        <Grid container>

          {!menu.hideTitle && <Grid item xs={12}>
            <Box display='flex' justifyContent='center' lineHeight={1}
              fontSize={matches && `${menu.font?.size?.title}rem`}
              color={menu.style?.title?.color?.hex}
              style={{
                paddingBottom: `${menu.spacing?.title}vh`
              }}
            >
              {menu.title}
            </Box>
          </Grid>}

          {/* Column */}
          {menu.items.length && menu.items.map((group, cindex) => {

            return <Grid item xs key={`${cindex}`} style={ menu.items.length-1==cindex ? {} : {paddingRight: `${menu.spacing?.tables}vw`} }>
              {/* Row  */}
              {group.group.map((item, rindex) => {
                if (item.type == 'table' && typeof item.collection == 'object') {
                  return <TableView table={item} menu={menu} key={`${rindex}`} orgId={orgId}/>
                }
                if (item.type == 'spacer') {
                  return <div style={{padding: 20}} key={`${rindex}`}></div>
                }
              })}
            </Grid>

          })}

        </Grid>
      </main>}

    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  center: {
    display: 'flex',
    alignItems: 'center', /* center items vertically */
    // justifyContent: 'center', /* center items horizontally */
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  timestamp: {
    margin: 5,
    bottom: 0,
    position: 'fixed',
    color: '#fff'
  }
}));


export default withFirebase(withAuthConsumer(ViewMenu));