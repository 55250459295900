import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

class Error extends Component {
  render() {
    return (
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <span className="clearfix">
              <>
                <h1 className="float-left display-3 mr-4">404</h1>
                <h4 className="pt-3">Oops! You're lost.</h4>
                <p className="text-muted float-left">The page you are looking for was not found.</p>
              </>
            </span>
            {/*<InputGroup className="input-prepend">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-search"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input size="16" type="text" placeholder="What are you looking for?" />
              <InputGroupAddon addonType="append">
                <Button color="info">Search</Button>
              </InputGroupAddon>
            </InputGroup>*/}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Error;