import React, { useState, useRef } from "react";
import { get, set } from 'lodash';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Select from 'react-select'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Form, Button, ButtonGroup, InputGroup, Col } from "react-bootstrap"
import { Grid, IconButton, TextField, makeStyles } from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ClearIcon from '@material-ui/icons/Clear';

const MySwal = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    padding: 0,
    position: 'absolute',
    top: 0,
    right: 0
  },
}));

const ArrangeMenu = ({ menuItems, setMenuItems, tables }) => {
  const classes = useStyles();

  const selectTableRef = useRef(null);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(menuItems[sInd], source.index, destination.index);
      const newMenuItems = [...menuItems];
      newMenuItems[sInd] = items;
      setMenuItems(newMenuItems);
    } else {
      const result = move(menuItems[sInd], menuItems[dInd], source, destination);
      const newMenuItems = [...menuItems];
      newMenuItems[sInd] = result[sInd];
      newMenuItems[dInd] = result[dInd];
      setMenuItems(newMenuItems);
    }
  }

  const addTable = async (index) => {
    // get element type (table, separator)
    const { value: element } = await Swal.fire({
      title: 'Add an Element',
      text: 'Add a header or spacer',
      input: 'radio',
      inputOptions: {
        'table': 'Table',
        'spacer': 'Spacer'
      },
      animation: false,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to choose something!'
        }
      }
    })

    let item = {}
    if (element == 'spacer') {
      item.id = `spacer-${new Date().getTime()}`
      item.type = 'spacer'
    } else if (element == 'table') {
      // get table name
      let { value: tableName } = await MySwal.fire({
        title: 'Select Table',
        html: <Select ref={selectTableRef} options={Object.keys(tables).map((t) => { return { label: t, value: t } })}/>,
        animation: false,
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        preConfirm: () => {
          return selectTableRef.current.state.value.value
      }})
      item = {
        id: `table-${new Date().getTime()}`,
        type: 'table',
        ...tables[tableName]
      }
    }
    if (Object.keys(item).length < 2) { return }

    const newMenuItems = [...menuItems]
    newMenuItems[index].push(item)
    setMenuItems(newMenuItems);
  }

  const removeColumn = (columnIndex) => {
    const newMenuItems = [...menuItems];
    newMenuItems.splice(columnIndex, 1)
    if (!newMenuItems.length) { // if it was last column, make sure we always have an empty one
      newMenuItems.push([])
    }
    setMenuItems(newMenuItems);
  }

  const removeItem = (itemIndex, columnIndex) => {
    const newMenuItems = [...menuItems];
    newMenuItems[columnIndex].splice(itemIndex, 1);
    setMenuItems(newMenuItems);
  }

  return (
    <Grid container spacing={1}>

      <Grid item xs={12}>
        <div style={{ display: "flex" }}>
          <DragDropContext onDragEnd={onDragEnd}>
            {menuItems.map((el, ind) => (
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {/* Column */}
                    <ButtonGroup style={{width: '100%'}}>
                      <Button
                        variant="outline-primary" size="sm"
                        style={{marginBottom: grid}}
                        onClick={() => addTable(ind)}
                      >
                        Add Element
                      </Button>
                      <Button
                        variant="outline-danger" size="sm"
                        style={{marginBottom: grid}}
                        onClick={() => removeColumn(ind)}
                      >
                        Remove Column
                      </Button>
                    </ButtonGroup>

                    {/* Item */}
                    {el.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(item,
                              snapshot.isDragging,
                              provided.draggableProps.style
                          )}>

                          {/* Element */}
                          {item.type === 'table' && <div style={{padding: `${grid}px ${grid*3}px ${grid}px ${grid}px`}}>
                            <h6 style={{margin: 0}}>{item.name}</h6>
                            Collection: {get(item, 'collection')}<br/>
                            Size: {get(item, 'columns.length')} columns x {get(item, 'rows.length')} rows
                            <IconButton onClick={() => removeItem(index, ind)} className={classes.deleteButton}>
                              <HighlightOffIcon />
                            </IconButton>
                          </div>}
                          {item.type === 'spacer' && <div style={{textAlign: 'center', padding: grid*2}}>
                            Spacer
                            <IconButton onClick={() => removeItem(index, ind)} className={classes.deleteButton}>
                              <HighlightOffIcon />
                            </IconButton>
                          </div>}

                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>

          <Button
            variant="light"
            style={{marginRight: grid, background: 'lightgrey'}}
            onClick={(e) => {
              e.target.blur()
              setMenuItems([...menuItems, []]);
            }}
          >
            Add Column
          </Button>
        </div>
      </Grid>

    </Grid>
  );
}

const grid = 8;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  margin: `0 ${grid}px 0 0`,
  width: 250
});
const getItemStyle = (item, isDragging, draggableStyle) => {
  let padding = `${grid*2}px 0 ${grid*2}px 0`
  if (item.type === 'divider' || item.type === 'header' || item.type === 'filter') {
    padding = `0`
  }
  let defaultBackground = 'white'
  if (item.type === 'filter') {
    defaultBackground = 'white'
  }
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: padding,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : defaultBackground,

    // relative position so we can position the label on top left
    position: 'relative',

    // styles we need to apply on draggables
    ...draggableStyle
  }
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default (ArrangeMenu)
