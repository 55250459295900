import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from "react-router-dom";
import clsx from 'clsx';

import { AppBar, Toolbar, List, Typography, Divider, IconButton, Badge, Button, MenuItem, Menu, makeStyles } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';

import logo from '../../logo.svg'
import { withAuthConsumer } from '../../session';
import { withFirebase } from '../../firebase';

const DashboardAppBar = ({ firebase, authUser, drawerOpen, hasDrawer }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={() => {
        firebase.doSignOut()
        handleMenuClose()
      }}>Log Out</MenuItem>
    </Menu>
  );

  return (
    <AppBar position="fixed" elevation={0} color="default" className={clsx(classes.appBar, hasDrawer && classes.appBarDrawer, drawerOpen && classes.appBarDrawerOpen)}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.title}>
          <Link to="/">
            <img className={classes.appbarHeaderLogo} src={logo} alt='App Logo'/>
          </Link>
        </div>

        {!authUser && <Button color="inherit" component={Link} to="/login">Login</Button>} 
        {!authUser && <Button color="inherit" component={Link} to="/signup">Signup</Button>}

        {authUser && <Typography color="inherit" noWrap>
          Logged in as {authUser.email}
        </Typography>}

        {authUser && <div>
          {/* <IconButton aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
          <IconButton aria-label="show 17 new notifications" color="inherit">
            <Badge badgeContent={17} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </div>}
        {renderMenu}
      </Toolbar>
    </AppBar>
  );
}

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  appbarHeaderLogo: {
    height: theme.mixins.toolbar.minHeight,
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBarDrawer: {
    zIndex: theme.zIndex.drawer + 1,
    // width: `calc(100% - ${theme.spacing(7)}px)`,
    // [theme.breakpoints.up('sm')]: {
    //   width: `calc(100% - ${theme.spacing(9)}px)`,
    // },
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
  },
  appBarDrawerOpen: {
    // width: `calc(100% - ${drawerWidth}px)`,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
}));

export default withFirebase(withAuthConsumer(DashboardAppBar))
