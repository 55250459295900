import Home from './pages/Home';
import Organizations from './pages/Organizations';

import Error from './pages/Error';

import LoginPage from './pages/Login';
import ForgotPage from './pages/Forgot';
import SignupPage from './pages/Signup';

import Overview from './pages/Overview'
import ManageCollections from './pages/ManageCollections';
import ManageMenus from './pages/ManageMenus';
import ManageTables from './pages/ManageTables';
import ManageFilters from './pages/ManageFilters';

export const ROUTES = [
  { 
    path: '/login', name: 'Login', 
    container: 'Standard', 
    component: LoginPage, 
    exact: true 
  }, { 
    path: '/forgot', name: 'Forgot', 
    container: 'Standard', 
    component: ForgotPage, 
    exact: true 
  }, { 
    path: '/signup', name: 'Signup', 
    container: 'Standard', 
    component: SignupPage,
    exact: true
  }, { 
    path: '/o/:orgId/collections', name: 'Manage Collections', 
    container: 'Dashboard', 
    component: ManageCollections,
    exact: true, auth: true
  }, { 
    path: '/o/:orgId/menus', name: 'Manage Menus', 
    container: 'Dashboard',
    component: ManageMenus, 
    exact: true, auth: true
  }, {
    path: '/o/:orgId/tables', name: 'Manage Tables', 
    container: 'Dashboard',
    component: ManageTables, 
    exact: true, auth: true
  }, {
    path: '/o/:orgId/filters', name: 'Manage Filters', 
    container: 'Dashboard',
    component: ManageFilters, 
    exact: true, auth: true
  }, { 
    path: '/o/:orgId', name: 'Overview', 
    container: 'Dashboard',
    component: Overview, 
    exact: true, auth: true
  }, { 
    path: '/', name: 'Organizations',
    container: 'Standard',
    component: Organizations, 
    exact: true, auth: true
  }, {
    path: '/', name: 'Home', 
    container: 'Standard', 
    component: Home,
    exact: true
  }, { 
    path: '/', name: 'Error',
    container: 'Standard', 
    component: Error, 
  },
];

export default ROUTES